import React, { useState, useEffect } from 'react';
import Header from '../../component/layout/Header';
import Footer from '../../component/layout/Footer';
import Page from '../../component/utility/Page';
import DataTable from 'react-data-table-component';
import { custTableStyles } from '../../style/color';

import AddEmployee from '../../component/utility/master/AddEmployee';

import { Row, Col, Card, CardHeader, CardBody, Form, Input, Label, InputGroup, InputGroupText, Spinner, Button } from 'reactstrap';
import {FaEye, FaEdit, FaPlusCircle, FaClipboardList} from 'react-icons/fa';
import {MdDeleteForever, MdAddCircle } from 'react-icons/md';

const Employee = () => {
    const [mode, setMode] = useState(1);
    const [loading, setLoading] = useState(false);
    const [tableData, setTableData] = useState('');

    const employee_list = () => {
        const list = [
            {
                ids: 1,
                name: 'Ravi Yadav (रवि यादव)',
                mobile: '9890098900',
                aadhar: 'XXXXXXXXXXXX8978',
                email: 'raviyadav@gmail.com',
                designation: 'Sales Manager',
                action: <div>
                            <FaEye className='me-3 cursor' color="#FFC000" size={20} />
                            <FaEdit className='me-3 cursor' color="#006600" size={20} />
                            <MdDeleteForever className='cursor' color="#f70000" size={20} />
                        </div>
            },
            {
                ids: 2,
                name: 'Soni Kumari (सोनी कुमारी)',
                mobile: '9890098900',
                aadhar: 'XXXXXXXXXXXX8541',
                email: 'soni@yahoo.com',
                designation: 'HR Manager',
                action: <div>
                            <FaEye className='me-3 cursor' color="#FFC000" size={20} />
                            <FaEdit className='me-3 cursor' color="#006600" size={20} />
                            <MdDeleteForever className='cursor' color="#f70000" size={20} />
                        </div>
            },
            {
                ids: 3,
                name: 'Garima Sinha (गरिमा सिन्हा)',
                mobile: '8989895555',
                aadhar: 'XXXXXXXXXXXX2277',
                email: 'garima_sinha@yahoo.com',
                designation: 'Sales Person',
                action: <div>
                            <FaEye className='me-3 cursor' color="#FFC000" size={20} />
                            <FaEdit className='me-3 cursor' color="#006600" size={20} />
                            <MdDeleteForever className='cursor' color="#f70000" size={20} />
                        </div>
            },
            {
                ids: 4,
                name: 'kuldeep singh (कुलदीप सिंह)',
                mobile: '8989895555',
                aadhar: 'XXXXXXXXXXXX8654',
                email: 'singhkuldeep@gmail.com',
                designation: 'Sales Person',
                action: <div>
                            <FaEye className='me-3 cursor' color="#FFC000" size={20} />
                            <FaEdit className='me-3 cursor' color="#006600" size={20} />
                            <MdDeleteForever className='cursor' color="#f70000" size={20} />
                        </div>
            },
            {
                ids: 5,
                name: 'Rakesh kumar (राकेश कुमार)',
                mobile: '7777895555',
                aadhar: 'XXXXXXXXXXXX1542',
                email: 'rakesh@gmail.com',
                designation: 'Sales Person',
                action: <div>
                            <FaEye className='me-3 cursor' color="#FFC000" size={20} />
                            <FaEdit className='me-3 cursor' color="#006600" size={20} />
                            <MdDeleteForever className='cursor' color="#f70000" size={20} />
                        </div>
            },
        ]

        setTableData(list);
    }

    const back_handler = () => {
        setMode(1);
    }


    return (
        <>
            <Header />
            <Page title='Employee' breadcrumbs='Home / Employee'>
                <Row className='p-3'>
                    { mode === 1 &&
                    <Col xs={12}>
                        <Card className='mb-3' color="primary" outline>
                            <CardBody>
                                <Row>
                                    <Col md={2}>
                                        <Button className='text-white' color='primary' size="sm" block onClick={employee_list}> <FaClipboardList className='me-1' size={20} /> Get Employee List</Button>
                                    </Col>
                                    <Col md={2}>
                                        <Button className='text-white' color='primary' size="sm" block onClick={() => setMode(2)}> <FaPlusCircle className='me-1' size={20} /> Add New Employee </Button>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                        <Card className='' color="primary" outline>
                            <CardBody>
                            <DataTable
                                title=""
                                customStyles={custTableStyles}
                                columns={[
                                    {
                                    name: 'Id',
                                    selector: row=>row.ids,
                                    sortable: true,
                                    width: '80px'
                                    },
                                    {
                                    name: 'Name',
                                    selector: row=>row.name,
                                    sortable: false,
                                    },
                                    {
                                    name: 'Mobile Number',
                                    selector: row=>row.mobile,
                                    sortable: false,
                                    },
                                    {
                                    name: 'Aadhar Number',
                                    selector: row=>row.aadhar,
                                    sortable: false,
                                    },
                                    {
                                    name: 'Email',
                                    selector: row=>row.email,
                                    sortable: false,
                                    },
                                    {
                                    name: 'Designation',
                                    selector: row=>row.designation,
                                    sortable: false,
                                    },
                                    {
                                    name: 'Action',
                                    selector: row=>row.action,
                                    sortable: false,
                                    },
                                ]}
                                data={tableData}
                                highlightOnHover
                                progressPending={loading}
                                />
                            </CardBody>
                        </Card>
                    </Col>
                    }

                    { mode === 2 &&
                        <Col xs={12}>
                            <AddEmployee back={()=>back_handler()}/>
                        </Col>
                    }
                </Row>

            </Page>
            <Footer />

        </>
    )

}

export default Employee;