import React, {useState} from 'react';
import Header from '../../component/layout/Header';
import Footer from '../../component/layout/Footer';
import Page from '../../component/utility/Page';

import { Row, Col, Card, CardHeader, CardBody, Input, Label, InputGroup, InputGroupText, Spinner, Button } from 'reactstrap';
import Badge from '@mui/material/Badge';
import {FaClipboardList, FaHistory} from 'react-icons/fa';
import {BsFillCartCheckFill} from 'react-icons/bs';

import ItemBox from '../../component/utility/advisory/ItemBox';
import CartDetails from '../../component/utility/advisory/CartDetails';
import OrderHistory from '../../component/utility/advisory/OrderHistory';

const InputCategory = () => {
    const [loading, setLoading] = useState(false);
    const [mode, setMode] = useState('1');
    const [productList, setProductList] = useState('');

    const get_input_list = () => {
        let list = [
            {
                name: "Spinach (पालक)",
                img: "1",
                unit: "10 gms",
                price: "36.00 / Packet",
                varity: "Organic",
            },
            {
                name: "Radish (मूली)",
                img: "2",
                unit: "10 gms",
                price: "30.00 / Packet",
                varity: "Organic",
            },
            {
                name: "French Bean (फरास बीन)",
                img: "3",
                unit: "10 gms",
                price: "33.00 / Packet",
                varity: "Organic",
            },
            {
                name: "Coriander (धनिया)",
                img: "4",
                unit: "10 gms",
                price: "15.00 / Packet",
                varity: "Organic",
            },
            {
                name: "Beet Root (चुकंदर)",
                img: "5",
                unit: "10 gms",
                price: "65.00 / Packet",
                varity: "Organic",
            },
            {
                name: "Pea (मटर)",
                img: "6",
                unit: "10 gms",
                price: "58.00 / Packet",
                varity: "Organic",
            },
            {
                name: "Sponge Gourd (तो‍री)",
                img: "7",
                unit: "10 gms",
                price: "28.00 / Packet",
                varity: "Organic",
            },
            {
                name: "Okra (भिण्‍डी)",
                img: "8",
                unit: "10 gms",
                price: "48.00 / Packet",
                varity: "Organic",
            },
        ]

        setProductList(list);
    }   

    const cart_detail_handler = () => {
        //API Call Cart Details
        setMode('2');

    }
    const back_handler = () => {
        setMode('1');
    }

    return(
        <>
            <Header />
            <Page title='Input' breadcrumbs='Home / Input'>
                { mode === '1' &&
                <Row className='p-3'>
                    <div style={{position: 'fixed', top: '75px',  right:'70px', width:'auto', zIndex: '999'}}>
                        <Button color="primary" className='text-white' onClick={cart_detail_handler}> 
                            <Badge badgeContent={4} color="secondary">
                                <BsFillCartCheckFill size={30} />
                            </Badge>
                        </Button>
                    </div>
                    <Col xs={12}>
                        <Card className='mb-3' color="primary" outline>
                            <CardBody>
                                <Row>
                                    <Col sm={3} className="mb-2">
                                        <Input id="type" bsSize="sm" type="select" >
                                            <option value="0"> --Select Input Type--</option>
                                            <option value="1"> Nutrition </option>
                                            <option value="2"> Horticulture </option>
                                            <option value="3"> Vegetable </option>
                                            <option value="4"> Aromatic </option>
                                            <option value="5"> Apiary </option>
                                        </Input>
                                    </Col>
                                    <Col md={2}>
                                        <Button className='text-white' color='primary' size="sm" onClick={get_input_list} block> <FaClipboardList className='me-1' size={20} /> Find Product</Button>
                                    </Col>
                                    <Col md={2}>
                                        <Button className='text-white' color='primary' size="sm" onClick={()=>setMode('5')} block> <FaHistory className='me-1' size={20} /> Order History</Button>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col xs={12}>
                        <Card className='' color="primary" outline>
                            <CardBody>
                                <Row>
                                    { productList.length > 0 &&
                                        productList.map((row, index) => {
                                            return (
                                                <ItemBox key={index} data={row} />
                                            )
                                        })
                                    }
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>

                </Row>
                }
                { mode === '2' &&
                <Row className='p-3'>
                    
                    <Col xs={12}>
                        <CartDetails back={back_handler} />
                    </Col>

                </Row>
                }
                { mode === '5' &&
                <Row className='p-3'>
                    
                    <Col xs={12}>
                        <OrderHistory back={back_handler} />
                    </Col>

                </Row>
                }

            </Page>
            <Footer />

        </>
    )
}

export default InputCategory;