import React, { useState, useEffect } from 'react';
import Header from '../../component/layout/Header';
import Footer from '../../component/layout/Footer';
import Page from '../../component/utility/Page';
import DataTable from 'react-data-table-component';
import { custTableStyles } from '../../style/color';

import { Row, Col, Card, CardHeader, CardBody, Form, Input, Label, InputGroup, InputGroupText, Spinner, Button } from 'reactstrap';
import {FaHeading, FaEdit, FaClipboardList, FaPlusCircle } from 'react-icons/fa';
import {MdDeleteForever, } from 'react-icons/md';

const Pg_Vo = () => {
    const [hindiNameLoading, sethindiNameLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [tableData, setTableData] = useState('');

    const [code, setCode] = useState('');
    const [name, setName] = useState('');
    const [nameHin, setNameHin] = useState('');
    const [mobileNo, setMobileNo] = useState('');
    const [district, setDistrict] = useState('');
    const [block, setBlock] = useState('');
    const [panchayat, setPanchayat] = useState('');
    const [village, setVillage] = useState('');

    const [mode, setMode] = useState("1");


    const pg_vo_list = () => {
        const list = [
            {
                ids: 1,
                name: 'Amar Jyoti PG',
                name_h: 'अमर ज्योति पीजी',
                district: 'Ranchi',
                block: 'Namkum',
                village: 'Mohanpur',
                action: <div>
                            <FaEdit className='me-3 cursor' color="#006600" size={20} />
                            <MdDeleteForever className='cursor' color="#f70000" size={20} />
                        </div>
            },
            {
                ids: 2,
                name: 'Dhanlaxmi VO',
                name_h: 'धनलक्ष्मी वीओ',
                district: 'Ranchi',
                block: 'Namkum',
                village: 'Rampur',
                action: <div>
                            <FaEdit className='me-3 cursor' color="#006600" size={20} />
                            <MdDeleteForever className='cursor' color="#f70000" size={20} />
                        </div>
            },
            {
                ids: 3,
                name: 'Maa Bhawani PG',
                name_h: 'माँ भवानी पीजी',
                district: 'Ranchi',
                block: 'Namkum',
                village: 'Fena Belahi',
                action: <div>
                            <FaEdit className='me-3 cursor' color="#006600" size={20} />
                            <MdDeleteForever className='cursor' color="#f70000" size={20} />
                        </div>
            },
            {
                ids: 4,
                name: 'Chaman VO',
                name_h: 'चमन वीओ',
                district: 'Ranchi',
                block: 'Namkum',
                village: 'Rahika tola',
                action: <div>
                            <FaEdit className='me-3 cursor' color="#006600" size={20} />
                            <MdDeleteForever className='cursor' color="#f70000" size={20} />
                        </div>
            },
            {
                ids: 5,
                name: 'Insaaf VO',
                name_h: 'इंसाफ़ वीओ',
                district: 'Ranchi',
                block: 'Namkum',
                village: 'Koltola',
                action: <div>
                            <FaEdit className='me-3 cursor' color="#006600" size={20} />
                            <MdDeleteForever className='cursor' color="#f70000" size={20} />
                        </div>
            },
        ]

        setTableData(list);
    }

    const add_handler = (e) => {

    }

    const translate_text_handler = (e) => {

    }

    const getBlockList = (e) => {

    }

    const getPanchayatList = (e) => {

    }

    const getVillageList = (e) => {

    }

    return (
        <>
            <Header />
            <Page title='PG / VO / SHG' breadcrumbs='Home / PG-VO-SHG'>
                <Row className='p-3'>
                    {mode === "1" &&
                    <Col xs={12}>
                        <Card className='mb-3' color="primary" outline>
                            <CardBody>
                                <Row>
                                <Col md={2}>
                                    <Button className='text-white' color='primary' size="sm" onClick={pg_vo_list} block><FaClipboardList className='me-1' size={20} /> Get PG / VO List </Button>
                                </Col>
                                <Col md={2}>
                                    <Button className='text-white' color='primary' size="sm" onClick={()=>setMode("2")} block><FaPlusCircle className='me-1' size={20} /> Add New PG / VO </Button>
                                </Col>
                                </Row>
                            </CardBody>
                        </Card>
                        <Card className='' color="primary" outline>
                            <CardBody>
                            <DataTable
                                title=""
                                customStyles={custTableStyles}
                                columns={[
                                    {
                                    name: 'Id',
                                    selector: row=>row.ids,
                                    sortable: true,
                                    width: '100px'
                                    },
                                    {
                                    name: 'PG/VO Name',
                                    selector: row=>row.name,
                                    sortable: false,
                                    },
                                    {
                                    name: 'PG/VO Name Hindi',
                                    selector: row=>row.name_h,
                                    sortable: false,
                                    },
                                    {
                                    name: 'District',
                                    selector: row=>row.district,
                                    sortable: false,
                                    },
                                    {
                                    name: 'Block',
                                    selector: row=>row.block,
                                    sortable: false,
                                    },
                                    {
                                    name: 'Village',
                                    selector: row=>row.village,
                                    sortable: false,
                                    },
                                    {
                                    name: 'Action',
                                    selector: row=>row.action,
                                    sortable: false,
                                    },
                                ]}
                                data={tableData}
                                highlightOnHover
                                progressPending={loading}
                                />
                            </CardBody>
                        </Card>
                    </Col>
                    }
                    {mode === "2" &&
                    <Col xs={12}>
                        <Card  className='mb-3' color="primary" outline>
                            <CardHeader>Add New PG / VO </CardHeader>
                            <CardBody>
                                <Form onSubmit={add_handler}>
                                    <Row>
                                        <Col md={2} className="mb-2">
                                            <Label for="code"> PG / VO Code</Label>
                                            <Input id="code" type='text' bsSize="sm" value={code} onChange={(e) => setCode(e.target.value)} required />
                                        </Col>
                                        <Col md={2} className="mb-2">
                                            <Label for="name">  PG / VO Name</Label>
                                            <Input id="name" type='text' bsSize="sm" value={name} onChange={(e) => setName(e.target.value)} required />
                                        </Col>
                                        <Col md={2} className="mb-2">
                                            <Label for="hName">  PG / VO Name Hindi</Label>
                                            <InputGroup size="sm">
                                                <Input id="hName" type='text' value={nameHin} bsSize="sm" readOnly />
                                                <InputGroupText className='bg-primary cursor' onClick={!hindiNameLoading ? translate_text_handler : undefined}>
                                                    {hindiNameLoading ? <Spinner color="secondary" size="sm">Loading...</Spinner> : <FaHeading color="#fff" />}
                                                </InputGroupText>
                                            </InputGroup>
                                        </Col>
                                        
                                        <Col md={2} className="mb-2">
                                            <Label for="name">District</Label>
                                            <Input id="district" bsSize="sm" type="select" value={district} onChange={(e) => getBlockList(e)}>
                                                <option value="0"> --Select District--</option>
                                                <option value="1"> Ranchi </option>
                                                
                                            </Input>
                                        </Col>
                                        <Col xs={2} className="mb-2">
                                            <Label for="block"> Block </Label>
                                            <Input id="block" bsSize="sm" type="select" value={block} onChange={(e) => getPanchayatList(e)}>
                                                <option value="0"> --Select Block--</option>
                                                <option value="1"> Ranchi </option>
                                            </Input>
                                        </Col>

                                        <Col xs={2} className="mb-2">
                                            <Label for="village"> Village </Label>
                                            <Input id="village" bsSize="sm" type="select" value={village} onChange={(e) => setVillage(e.target.value)} >
                                                <option value="0"> --Select Village--</option>
                                                <option value="1"> Ranchi </option>
                                            </Input>
                                        </Col>
                                        <hr />
                                        <Col md={2} className="mb-2">
                                            <Button className='text-white' color='primary' size="sm" block> Submit </Button>
                                        </Col>
                                    </Row>
                                </Form>    
                            </CardBody>
                        </Card>
                        <div className='text-end'>
                            <Button className='text-white' color='primary' size="sm" onClick={() => setMode("1")}> Back </Button>
                        </div>
                    </Col>
                    }
                </Row>

            </Page>
            <Footer />

        </>
    )

}

export default Pg_Vo;