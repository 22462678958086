import React, { useState } from 'react';
import Header from '../../component/layout/Header';
import Footer from '../../component/layout/Footer';
import Page from '../../component/utility/Page';
import DataTable from 'react-data-table-component';
import { custTableStyles } from '../../style/color';
import axios from 'axios';
import { mainurl } from '../../Mainurl';
import Switch from '@mui/material/Switch';

import AddUser from '../../component/utility/master/AddUser';
import AssignUser from '../../component/utility/master/AssignUser';
import AssignUserView from '../../component/utility/master/AssignUserView';

import { Row, Col, Card, CardBody, Button } from 'reactstrap';
import {FaEdit, FaPlusCircle, FaClipboardList} from 'react-icons/fa';
import {MdAssignmentTurnedIn} from 'react-icons/md';

const UserManagement = () => {
    const [mode, setMode] = useState(1);
    const [loading, setLoading] = useState(false);
    const [tableData, setTableData] = useState('');
    const [crrUser, setCrrUser] = useState('');

    const user_list = () => {
        setLoading(true);
        setTableData('');
        let postData = [{"org_id": sessionStorage.getItem('org_id'), "user_id": "101"}];
        axios({
            method: 'post',
            url: mainurl + 'Master/box_user_list',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                
                let list = response.data.map((row, index) => {
                    let active = false;
                    if(row.active_flag === "1"){
                        active = true;
                    }
                    return(
                        {
                            ids: index + 1 ,
                            name: row.user_name +" ("+ row.user_name_ne +")",
                            mobile: row.user_phone,
                            email: row.user_email,
                            user_id: row.user_code,
                            designation: row.role_desc,
                            action: <div>
                                        <FaEdit className='me-3 cursor' onClick={()=> user_assign_view_handler(row)} color="#006600" size={20} />
                                        <Switch defaultChecked={active} size="small" color="success" />
                                    </div>
                                    }
                    )
                });

                setTableData(list);
                setLoading(false);
            })
            .catch(error => {
                setLoading(false);
                //let notify = notification({ message: "User list not Found! Try Again", type: 'error' });
                //notify();
            });
        
    }

    const user_assign_view_handler = (row) => {
        setCrrUser(row);
        setMode(4);
    }

    const back_handler = () => {
        setMode(1);
    }


    return (
        <>
            <Header />
            <Page title='User Management' breadcrumbs='Home / User Management'>
                <Row className='p-3'>
                    { mode === 1 &&
                    <Col xs={12}>
                        <Card className='mb-3' color="primary" outline>
                            <CardBody>
                                <Row>
                                    <Col md={2}>
                                        <Button className='text-white' color='primary' size="sm" block onClick={user_list}> <FaClipboardList className='me-1' size={20} /> Get User List</Button>
                                    </Col>
                                    <Col md={2}>
                                        <Button className='text-white' color='primary' size="sm" block onClick={() => setMode(2)}> <FaPlusCircle className='me-1' size={20} /> Add New User </Button>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                        <Card className='' color="primary" outline>
                            <CardBody>
                            <DataTable
                                title=""
                                customStyles={custTableStyles}
                                columns={[
                                    {
                                    name: 'Id',
                                    selector: row=>row.ids,
                                    sortable: true,
                                    width: '80px'
                                    },
                                    {
                                    name: 'Name',
                                    selector: row=>row.name,
                                    sortable: false,
                                    width: "20%"
                                    },
                                    {
                                    name: 'Mobile Number',
                                    selector: row=>row.mobile,
                                    sortable: false,
                                    },
                                    {
                                    name: 'Email',
                                    selector: row=>row.email,
                                    sortable: false,
                                    },
                                    {
                                    name: 'Login Id',
                                    selector: row=>row.user_id,
                                    sortable: false,
                                    },
                                    {
                                    name: 'Designation',
                                    selector: row=>row.designation,
                                    sortable: false,
                                    },
                                    {
                                    name: 'Action',
                                    selector: row=>row.action,
                                    sortable: false,
                                    },
                                ]}
                                data={tableData}
                                highlightOnHover
                                progressPending={loading}
                                />
                            </CardBody>
                        </Card>
                    </Col>
                    }

                    { mode === 2 &&
                        <Col xs={12}>
                            <AddUser back={()=>back_handler()} reset={user_list} />
                        </Col>
                    }

                    { mode === 3 &&
                        <Col xs={12}>
                            <AssignUser back={()=>back_handler()}/>
                        </Col>
                    }

                    { mode === 4 &&
                        <Col xs={12}>
                            <AssignUserView user={crrUser} back={()=>back_handler()}/>
                        </Col>
                    }
                </Row>

            </Page>
            <Footer />

        </>
    )

}

export default UserManagement;