import React, { useState, useEffect } from 'react';
import Header from '../../component/layout/Header';
import Footer from '../../component/layout/Footer';
import Page from '../../component/utility/Page';
import DataTable from 'react-data-table-component';
import { custTableStyles } from '../../style/color';
import axios from 'axios';
import { mainurl } from '../../Mainurl';

import { Row, Col, Card, CardHeader, CardBody, Form, Input, Label, InputGroup, InputGroupText, Spinner, Button } from 'reactstrap';
import {FaHeading, FaEdit, FaClipboardList, FaPlusCircle} from 'react-icons/fa';
import {MdDeleteForever, } from 'react-icons/md';

const Uom = () => {
    const [hindiNameLoading, sethindiNameLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [tableData, setTableData] = useState('');

    const [code, setCode] = useState('');
    const [name, setName] = useState('');
    const [nameHin, setNameHin] = useState('');

    const [mode, setMode] = useState('1');


    const uom_list = () => {
        let postData = [{"org_id": "101"}];
        axios({
            method: 'post',
            url: mainurl + 'Master/box_uom_list',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                let list = response.data.map((row, index) => {
                    let active = false;
                    if(row.active_flag === "1"){
                        active = true;
                    }
                    return(
                        {
                            ids: index + 1 ,
                            name: row.uom_desc,
                            name_h: row.uom_desc_ne,
                            action: <div>
                                        <FaEdit className='me-3 cursor' color="#006600" size={20} />
                                        <MdDeleteForever className='cursor' color="#f70000" size={20} />
                                    </div>
                        }
                    )
                });

                setTableData(list);
            })
            .catch(error => {
                setLoading(false);
                //let notify = notification({ message: "User list not Found! Try Again", type: 'error' });
                //notify();
            });
    }

    const add_handler = (e) => {

    }

    const translate_text_handler = (e) => {

    }

    return (
        <>
            <Header />
            <Page title='Unit of Measurment' breadcrumbs='Home / Unit of Measurment'>
                <Row className='p-3'>
                    { mode === "1" &&
                    <Col md={{offset: 3, order: 2, size: 6}}>   
                        <Card className='mb-3' color="primary" outline>
                            <CardBody>
                                <Row>
                                <Col md={6}>
                                    <Button className='text-white' color='primary' size="sm" onClick={uom_list} block><FaClipboardList className='me-1' size={20} /> Get UOM List </Button>
                                </Col>
                                <Col md={6}>
                                    <Button className='text-white' color='primary' size="sm" onClick={()=>setMode("2")} block><FaPlusCircle className='me-1' size={20} /> Add New UOM </Button>
                                </Col>
                                </Row>
                            </CardBody>
                        </Card> 
                        <Card className='' color="primary" outline>
                            <CardBody>
                            <DataTable
                                title=""
                                customStyles={custTableStyles}
                                columns={[
                                    {
                                    name: 'Id',
                                    selector: row=>row.ids,
                                    sortable: true,
                                    width: '100px'
                                    },
                                    {
                                    name: 'UOM Name',
                                    selector: row=>row.name,
                                    sortable: false,
                                    },
                                    {
                                    name: 'UOM Name Hindi',
                                    selector: row=>row.name_h,
                                    sortable: false,
                                    },
                                    {
                                    name: 'Action',
                                    selector: row=>row.action,
                                    sortable: false,
                                    },
                                ]}
                                data={tableData}
                                highlightOnHover
                                progressPending={loading}
                                />
                            </CardBody>
                        </Card>
                    </Col>
                    }
                    { mode === "2" &&
                        <Col xs={12}>
                            <Card  className='mb-3' color="primary" outline>
                                <CardHeader>Add New UOM </CardHeader>
                                <CardBody>
                                    <Form onSubmit={add_handler}>
                                        <Row>
                                            <Col md={2} className="mb-2">
                                                <Label for="code"> UOM Code</Label>
                                                <Input id="code" type='text' bsSize="sm" value={code} onChange={(e) => setCode(e.target.value)} required />
                                            </Col>
                                            <Col md={2} className="mb-2">
                                                <Label for="name">  UOM Name</Label>
                                                <Input id="name" type='text' bsSize="sm" value={name} onChange={(e) => setName(e.target.value)} required />
                                            </Col>
                                            <Col md={2} className="mb-2">
                                                <Label for="hName">  UOM Name Hindi</Label>
                                                <InputGroup size="sm">
                                                    <Input id="hName" type='text' value={nameHin} bsSize="sm" readOnly />
                                                    <InputGroupText className='bg-primary cursor' onClick={!hindiNameLoading ? translate_text_handler : undefined}>
                                                        {hindiNameLoading ? <Spinner color="secondary" size="sm">Loading...</Spinner> : <FaHeading color="#fff" />}
                                                    </InputGroupText>
                                                </InputGroup>
                                            </Col>
                                        
                                            <Col md={2} className="mb-2">
                                                <Button className='mt-4 text-white' color='primary' size="sm" block> Submit </Button>
                                            </Col>
                                        </Row>
                                    </Form>    
                                </CardBody>
                            </Card>
                            <div className='text-end'>
                                <Button className='text-white' color='primary' size="sm" onClick={() => setMode("1")}> Back </Button>
                            </div>
                        </Col>
                    }
                </Row>

            </Page>
            <Footer />

        </>
    )

}

export default Uom;