import React from 'react';
import Header from '../component/layout/Header';
import Footer from '../component/layout/Footer';
import Page from '../component/utility/Page';

import { Row, Col, Card, CardBody } from 'reactstrap';

const WIP = () => {
    return (
        <>
            <Header />
            <Page title='Work in progress' breadcrumbs='Home / Work in progress' >
                <Row className='p-3'>
                    <Col xs="12">
                        <Card color="primary" outline>
                            <CardBody className='text-center'>
                                <img className='img-fluid' alt="loading..." src={require('./../assets/img/wip.gif')} />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Page>
            <Footer />
        </>
    )
}

export default WIP;