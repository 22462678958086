import React, { useEffect, useState } from 'react';

import { Row, Col, Card, CardHeader, CardBody, Button } from 'reactstrap';
import CartItem from './CartItem';


const CartDetails = ({ data, back, ...props }) => {
    const [cartList, setCartList] = useState('');

    useEffect(() => {
        cart_list_handler();
    }, [])

    const cart_list_handler = () => {
        let list = [
            {
                name: "Spinach (पालक)",
                img: "1",
                unit: "10 gms",
                price: "36.00 / Packet",
                varity: "Organic",
                quantity: "3",
                total: "108.00",
            },
            {
                name: "French Bean (फरास बीन)",
                img: "3",
                unit: "10 gms",
                price: "33.00 / Packet",
                varity: "Organic",
                quantity: "10",
                total: "330.00",
            },
            {
                name: "Pea (मटर)",
                img: "6",
                unit: "10 gms",
                price: "58.00 / Packet",
                varity: "Organic",
                quantity: "10",
                total: "580.00",
            },
            {
                name: "Okra (भिण्‍डी)",
                img: "8",
                unit: "10 gms",
                price: "48.00 / Packet",
                varity: "Organic",
                quantity: "15",
                total: "720.00",
            },
        ]

        setCartList(list);
    }

    return (
        <>
        <Card className='' color="primary" outline>
            <CardHeader>Cart Details</CardHeader>
            <CardBody>
                <Row>
                    <Col xs={12}>
                        <Row className='borderBottom pb-1'>
                            <Col className='fb-750' xs={2}>Product Name</Col>
                            <Col className='fb-750' xs={1}>Image</Col>
                            <Col className='fb-750' xs={2}>Unit</Col>
                            <Col className='fb-750' xs={2}>Price</Col>
                            <Col className='fb-750' xs={1}>Quantity</Col>
                            <Col className='fb-750' xs={2}>Total</Col>
                            <Col className='fb-750' xs={2}>Delete</Col>
                        </Row>
                        {cartList.length > 0 &&
                            cartList.map((row) => {
                                return (
                                    <CartItem data={row} />
                                )
                            })
                        }
                        <Row className='borderBottomSecondary borderTop pb-1 pt-1'>
                            <Col className='fb-750' xs={{ offset: 8, order: 1, size: 2 }}>Sub-Total</Col>
                            <Col className='fb-750' xs={{ offset: 0, order: 2, size: 2 }}>&#8377; 1738.00</Col>
                        </Row>
                        <Row className='borderDashed pb-1 pt-1'>
                            <Col className='fb-750' xs={{ offset: 8, order: 1, size: 2 }}>Shipping Cost</Col>
                            <Col className='fb-750' xs={{ offset: 0, order: 2, size: 2 }}>&#8377; 300.00</Col>
                        </Row>
                        <Row className='pt-2'>
                            <Col className='fb-750' xs={{ offset: 8, order: 1, size: 2 }}>Grand Total</Col>
                            <Col className='fb-750' xs={{ offset: 0, order: 2, size: 2 }}>&#8377; 2038.00</Col>
                        </Row>
                    </Col>
                </Row>
            </CardBody>
        </Card>
        <div className='d-flex justify-content-between mt-3'>
            <Button className='text-white' color='primary' size="sm" onClick={back}> Back </Button>
            <Button className='text-white' color='primary' size="sm" > Proceed to payment</Button>
        </div>
        </>

    )

}

export default CartDetails;