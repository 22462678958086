export function getCurrentMonthYear() {
    let dt = new Date();
    let cYear = dt.getFullYear();
    let cMonth = (dt.getMonth() + 1).toString().padStart(2, "0");

    // formate 2022-01
    let send = cYear + "-" + cMonth;

    return send;
}

export function getMaxMonthYear(max) {
    let dt = new Date();
    let cYear = dt.getFullYear();
    let cMonth = (dt.getMonth() + max).toString().padStart(2, "0");

    // formate 2022-01
    let send = cYear + "-" + cMonth;

    return send;
}

export function getTodayDate() {
    let dt = new Date();
    let cYear = dt.getFullYear();
    let cMonth = (dt.getMonth() + 1).toString().padStart(2, "0");
    let cdate = dt.getDate();

    // formate yyyy-mm-dd
    let send = cYear + "-" + cMonth + "-" + cdate;

    return send;
}

export function getMinDate(min) {
    let dt = new Date();
    let cYear = dt.getFullYear();
    let cMonth = (dt.getMonth() + 1 - min).toString().padStart(2, "0");
    let cdate = dt.getDate();

    // formate yyyy-mm-dd
    let send = cYear + "-" + cMonth + "-" + cdate;

    return send;
}

export function NumInWords (number) {
    const first = ['','One ','Two ','Three ','Four ', 'Five ','Six ','Seven ','Eight ','Nine ','Ten ','Eleven ','Twelve ','Thirteen ','Fourteen ','Fifteen ','Sixteen ','Seventeen ','Eighteen ','Nineteen '];
    const tens = ['', '', 'Twenty','Thirty','Forty','Fifty', 'Sixty','Seventy','Eighty','Ninety'];
    const mad = ['', 'Thousand', 'Million', 'Billion', 'Trillion'];
    let word = '';
  
    for (let i = 0; i < mad.length; i++) {
      let tempNumber = number%(100*Math.pow(1000,i));
      if (Math.floor(tempNumber/Math.pow(1000,i)) !== 0) {
        if (Math.floor(tempNumber/Math.pow(1000,i)) < 20) {
          word = first[Math.floor(tempNumber/Math.pow(1000,i))] + mad[i] + ' ' + word;
        } else {
          word = tens[Math.floor(tempNumber/(10*Math.pow(1000,i)))] + '-' + first[Math.floor(tempNumber/Math.pow(1000,i))%10] + mad[i] + ' ' + word;
        }
      }
  
      tempNumber = number%(Math.pow(1000,i+1));
      if (Math.floor(tempNumber/(100*Math.pow(1000,i))) !== 0) word = first[Math.floor(tempNumber/(100*Math.pow(1000,i)))] + 'Hunderd ' + word;
    }
      return word;
  }