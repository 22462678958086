import React, {useState, useEffect} from 'react';
import { notification } from '../../hocs/notification';

import axios from 'axios';
import { mainurl } from '../../../Mainurl';
import Select from 'react-select';
import { Row, Col, Card, CardBody, CardHeader, Form, FormGroup, Input, Label, Button } from 'reactstrap';

const AssignUser = ({back, ...props}) => {
    const [loading, setLoading] = useState(false);

    const [userList, setUserList] = useState('');
    const [user, setUser] = useState('0');

    const [state, setState] = useState('');
    const [district, setDistrict] = useState('');
    const [block, setBlock] = useState('');
    const [village, setVillage] = useState('');

    const [stateOpt, setStateOpt] = useState('');
    const [districtOpt, setDistrictOpt] = useState('');
    const [blockOpt, setBlockOpt] = useState('');
    const [villageOpt, setVillageOpt] = useState('');
    
    useEffect(()=>{
        getUserList();
        get_state_list();
    },[])

    const getUserList = () => {
        let postData = [{"org_id": sessionStorage.getItem('org_id'), "user_id": "101"}];
        axios({
            method: 'post',
            url: mainurl + 'Master/box_user_list',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                
                let list = response.data.map((row) => {
                    return(
                        <option value={row.user_code}> {row.user_name +" ("+ row.user_name_ne +")"} </option> 
                    )
                });

                setUserList(list);
            })
            .catch(error => {
                //setLoading(false);
                //let notify = notification({ message: "User list not Found! Try Again", type: 'error' });
                //notify();
            });
    }
    
    const get_state_list = (e) => {
        
        let postData = [{"country_code": "1"}]
        axios({
            method: 'post',
            url: mainurl + 'Master/get_state_list',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                let list = response.data.map((row, index) => {
                    return(
                        <option value={row.state_code}> {row.state_name}</option>
                    )
                });

                setStateOpt(list);
            })
            .catch(error => {
                setLoading(false);
                //let notify = notification({ message: "User list not Found! Try Again", type: 'error' });
                //notify();
            });
    }

    const get_dist_list = (e) => {
        setState(e.target.value);
        let postData = [{"state_code": e.target.value}]
        axios({
            method: 'post',
            url: mainurl + 'Master/get_dist_list',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                let list = response.data.map((row, index) => {
                    return(
                        <option value={row.district_code}> {row.district_name}</option>
                    )
                });

                setDistrictOpt(list);
            })
            .catch(error => {
                setLoading(false);
                //let notify = notification({ message: "User list not Found! Try Again", type: 'error' });
                //notify();
            });
    }

    const get_block_list = (e) => {
        setDistrict(e.target.value);
        let postData = [{"dist_code": e.target.value}]
        axios({
            method: 'post',
            url: mainurl + 'Master/get_block_list',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                let list = response.data.map((row, index) => {
                    return(
                        <option value={row.block_code}> {row.block_name}</option>
                    )
                });

                setBlockOpt(list);
            })
            .catch(error => {
                setLoading(false);
                //let notify = notification({ message: "User list not Found! Try Again", type: 'error' });
                //notify();
            });
    }

    const get_village_list = (e) => {
        setBlock(e.target.value);

        let postData = [{"block_code": e.target.value}]
        axios({
            method: 'post',
            url: mainurl + 'Master/get_village_list',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                let list = response.data.map((row, index) => {
                    return(
                        { value: row.village_code, label: row.village_name }
                    )
                });

                setVillageOpt(list);
            })
            .catch(error => {
                setLoading(false);
                //let notify = notification({ message: "User list not Found! Try Again", type: 'error' });
                //notify();
            });
    }

    const assign_handler = (e) => {
        e.preventDefault();
        console.log(village);
        let postData = [{
            "org_id": sessionStorage.getItem('org_id'),
            "user_id": user,
            "state_id": state,
            "district_id": district,
            "block_id": block,
            "panchayat_id": 0,
            "village_list": village
        }];

        axios({
            method: 'post',
            url: mainurl + 'Master/user_allocation',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                if(response.data[0].json_status === "success"){
                    let notify = notification({ message: "User assign successfully.", type: 'success' });
                    notify(); 
                    back();
                }else{
                    let notify = notification({ message: "Submit failed!", type: 'error' });
                    notify(); 
                }
            })
            .catch(error => {
                setLoading(false);
                //let notify = notification({ message: "User list not Found! Try Again", type: 'error' });
                //notify();
            });
    }

    const select_vill_handler = (opt) => {
        let list = [];
        opt.map(row => {
            list.push(row.value);
        })
        console.log(list);
        setVillage(list);
    }

    return(
        <>
        <Card className='mb-3' color="primary" outline>
            <CardHeader> Assign User with village</CardHeader>
            <CardBody>
                <Form onSubmit={assign_handler}>
                    <Row>
                        <FormGroup row>
                            <Label for="user" sm={2} > User Name </Label>
                            <Col sm={4}>
                                <Input id="user" bsSize="sm" type="select" value={user} onChange={(e) => setUser(e.target.value)} >
                                    <option value="0"> --Select User--</option>
                                    {userList}
                                </Input>
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label for="state" sm={2} > State </Label>
                            <Col sm={4}>
                                <Input id="state" bsSize="sm" type="select" value={state} onChange={get_dist_list}>
                                    <option value="0"> --Select State--</option>
                                    {stateOpt}
                                </Input>
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label for="district" sm={2} > District </Label>
                            <Col sm={4}>
                                <Input id="district" bsSize="sm" type="select" value={district} onChange={get_block_list}>
                                    <option value="0"> --Select District--</option>
                                    {districtOpt}
                                </Input>
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label for="block" sm={2} > Block </Label>
                            <Col sm={4}>
                                <Input id="block" bsSize="sm" type="select" value={block} onChange={get_village_list}>
                                    <option value="0"> --Select Block--</option>
                                    {blockOpt}
                                </Input>
                            </Col>
                        </FormGroup>
                        
                        <FormGroup row>
                            <Label for="village" sm={2} > Village </Label>
                            <Col sm={4}>
                                <Select closeMenuOnSelect={false} isMulti
                                    options={villageOpt}
                                    onChange={select_vill_handler}
                                />
                            </Col>
                        </FormGroup>
                        
                        
                        <hr />
                        <Col md={2} className="mb-2">
                            <Button className='text-white' color='primary' size="sm" block> Assigned </Button>
                        </Col>
                    </Row>
                </Form>
            </CardBody>  
        </Card>
        <div className='text-end mt-3'>
            <Button className='text-white' color='primary' size="sm" onClick={back}> Back </Button>
        </div>
        </>
    )
}

export default AssignUser;