import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import { custTableStyles } from '../../../style/color';

import { Row, Col, Card, CardBody } from 'reactstrap';

const ExtraActivity = (h_data) => {
    const [tableData, setTableData] = useState('');

    useEffect(()=> {
        setTableData(h_data.h_data);
    },[])
    return(
        <Row>
            <Col xs={12}>
                <Card className='' color="primary" outline>
                    <CardBody>
                        <DataTable
                            title=""
                            customStyles={custTableStyles}
                            columns={[
                                {
                                    name: '#',
                                    selector: row => row.sl,
                                    sortable: true,
                                    width: '80px'
                                },
                                {
                                    name: 'Name',
                                    selector: row => row.name,
                                    sortable: false,
                                },
                                {
                                    name: 'Date',
                                    selector: row => row.h_date,
                                    sortable: false,
                                }
                             
                            ]}
                            data={tableData}
                            highlightOnHover
                            progressPending={!tableData}
                        />

                    </CardBody>
                </Card>
            </Col>
        </Row>
    )
}
export default ExtraActivity;