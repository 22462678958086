import React, { useEffect, useState } from 'react';

import { custTableStyles } from '../../../style/color';
import DataTable from 'react-data-table-component';

import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';

import { Row, Col, Card, CardHeader, CardBody, Button, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { FaEye, FaSearch, } from 'react-icons/fa';

const OrderHistory = ({ back, ...props }) => {
    const [loading, setLoading] = useState(false);
    const [tableData, setTableData] = useState('');
    const [viewModal, setViewModal] = useState(false);
    const [modalData, setModalData] = useState(false);

    useEffect(() => {
        get_order_list();
    }, [])

    const get_order_list = () => {
        let list = [
            {
                sl: "1",
                date: "02-09-2022",
                ord_no: "AGA100523",
                total_items: "4",
                ord_amt: <span>&#8377; 2038.00</span>,
                pay_status: <span className='text-danger'>Not Paid</span>,
                ord_status: "Pending",
                action: <FaEye className='me-3 cursor' color="#FFC000" size={20} onClick={order_detail_handler} />
            },
            {
                sl: "2",
                date: "20-08-2022",
                ord_no: "AGA100450",
                total_items: "3",
                ord_amt: <span>&#8377; 2095.00</span>,
                pay_status: <span className='text-success'>Paid</span>,
                ord_status: "Delivered",
                action: <FaEye className='me-3 cursor' color="#FFC000" size={20} onClick={order_detail_handler} />
            },
            {
                sl: "3",
                date: "10-07-2022",
                ord_no: "AGA100359",
                total_items: "1",
                ord_amt: <span>&#8377; 915.00</span>,
                pay_status: <span className='text-success'>Paid</span>,
                ord_status: "Delivered",
                action: <FaEye className='me-3 cursor' color="#FFC000" size={20} onClick={order_detail_handler} />
            },
            {
                sl: "4",
                date: "15-06-2022",
                ord_no: "AGA100109",
                total_items: "6",
                ord_amt: <span>&#8377; 3015.00</span>,
                pay_status: <span className='text-success'>Paid</span>,
                ord_status: "Delivered",
                action: <FaEye className='me-3 cursor' color="#FFC000" size={20} onClick={order_detail_handler} />
            },

        ]
        setTableData(list);
    }

    const order_detail_handler = () => {
        let list = [
            {
                sl: "1",
                name: "Spinach (पालक)",
                image: <img className='img-fluid' width="70px" alt="load..." src={require("../../../assets/img/cropImg/1.png")} />,
                unit: "10 gms",
                price: "36.00 / Packet",
                varity: "Organic",
                quantity: "3",
                total: <span>&#8377; 108.00</span>,
            },
            {
                sl: "2",
                name: "French Bean (फरास बीन)",
                image: <img className='img-fluid' width="70px" alt="load..." src={require("../../../assets/img/cropImg/3.png")} />,
                unit: "10 gms",
                price: "33.00 / Packet",
                varity: "Organic",
                quantity: "10",
                total: <span>&#8377; 330.00</span>,
            },
            {
                sl: "3",
                name: "Pea (मटर)",
                image: <img className='img-fluid' width="70px" alt="load..." src={require("../../../assets/img/cropImg/6.png")} />,
                unit: "10 gms",
                price: "58.00 / Packet",
                varity: "Organic",
                quantity: "10",
                total: <span>&#8377; 580.00</span>,
            },
            {
                sl: "4",
                name: "Okra (भिण्‍डी)",
                image: <img className='img-fluid' width="70px" alt="load..." src={require("../../../assets/img/cropImg/8.png")} />,
                unit: "10 gms",
                price: "48.00 / Packet",
                varity: "Organic",
                quantity: "15",
                total: <span>&#8377; 720.00</span>,
            },

        ]

        setModalData(list);
        setViewModal(true);
    }

    return (
        <>
            <Card className='' color="primary" outline>
                <CardHeader>Order History</CardHeader>
                <CardBody>
                    <DataTable
                        title=""
                        customStyles={custTableStyles}
                        columns={[
                            {
                                name: '#',
                                selector: row => row.sl,
                                sortable: true,
                                width: '80px'
                            },
                            {
                                name: 'Order Date',
                                selector: row => row.date,
                                sortable: false,
                            },
                            {
                                name: 'Order Number',
                                selector: row => row.ord_no,
                                sortable: false,
                            },
                            {
                                name: 'Total Items',
                                selector: row => row.total_items,
                                sortable: false,
                            },
                            {
                                name: 'Order Amount',
                                selector: row => row.ord_amt,
                                sortable: false,
                            },
                            {
                                name: 'Payment Status',
                                selector: row => row.pay_status,
                                sortable: false,
                            },
                            {
                                name: 'Order Status',
                                selector: row => row.ord_status,
                                sortable: false,
                            },
                            {
                                name: 'Action',
                                selector: row => row.action,
                                sortable: false,
                            },
                        ]}
                        data={tableData}
                        highlightOnHover
                        progressPending={loading}
                    />

                </CardBody>
            </Card>

            <div className='text-end mt-3'>
                <Button className='text-white' color='primary' size="sm" onClick={back}> Back </Button>
            </div>

            <Modal
                fullscreen={true}
                centered
                scrollable
                size="lg"
                isOpen={viewModal}
                toggle={() => setViewModal(!viewModal)}
            >
                <ModalHeader toggle={() => setViewModal(!viewModal)}>
                    Order Detail
                </ModalHeader>
                <ModalBody>
                    <Row style={{borderBottom: '2px solid #4d8d6e'}}>
                        <Col md={5} className="mb-3">
                            <div className='d-flex justify-content-between mb-3'>
                                <div className="text-muted"> Order Number </div>
                                <div className=""> AGA100523 </div>
                            </div>
                            <div className='d-flex justify-content-between mb-3'>
                                <div className="text-muted"> Order Date </div>
                                <div className=""> 02-09-2022 </div>
                            </div>
                            <div className='d-flex justify-content-between mb-3'>
                                <div className="text-muted"> Payment Status </div>
                                <div className=""> <span className='text-danger'>Not Paid</span> </div>
                            </div>
                            <div className='d-flex justify-content-between mb-3'>
                                <div className="text-muted"> Order Status </div>
                                <div className=""> Packaging </div>
                            </div>
                        </Col>
                        <Col md={7} style={{borderLeft: "1px solid #4d8d6e"}}>
                            <Timeline position="alternate">
                                <TimelineItem>
                                    <TimelineSeparator>
                                        <TimelineDot variant="outlined" color="primary" />
                                        <TimelineConnector />
                                    </TimelineSeparator>
                                    <TimelineContent>Placed (02-09-2022)</TimelineContent>
                                </TimelineItem>
                                <TimelineItem>
                                    <TimelineSeparator>
                                        <TimelineDot variant="outlined" color="primary" />
                                        <TimelineConnector />
                                    </TimelineSeparator>
                                    <TimelineContent>Packaging (04-09-2022)</TimelineContent>
                                </TimelineItem>
                                <TimelineItem>
                                    <TimelineSeparator>
                                    <TimelineDot variant="outlined" />
                                    </TimelineSeparator>
                                    <TimelineContent>Delivered</TimelineContent>
                                </TimelineItem>
                            </Timeline>
                        </Col>
                    </Row>
                    <DataTable
                        title=""
                        customStyles={custTableStyles}
                        columns={[
                            {
                                name: '#',
                                selector: row => row.sl,
                                sortable: true,
                                width: '50px'
                            },
                            {
                                name: 'Product Name',
                                selector: row => row.name,
                                sortable: false,
                                width: '25%'
                            },
                            {
                                name: 'Image',
                                selector: row => row.image,
                                sortable: false,
                            },
                            {
                                name: 'Unit',
                                selector: row => row.unit,
                                sortable: false,
                            },
                            {
                                name: 'Price',
                                selector: row => row.price,
                                sortable: false,
                            },
                            {
                                name: 'Quantity',
                                selector: row => row.quantity,
                                sortable: false,
                            },
                            {
                                name: 'Total',
                                selector: row => row.total,
                                sortable: false,
                            },
                        ]}
                        data={modalData}
                        highlightOnHover
                        progressPending={loading}
                    />
                    <Row className='borderBottomSecondary borderTop pb-1 pt-1'>
                        <Col className='fb-750' xs={{ offset: 8, order: 1, size: 2 }}>Sub-Total</Col>
                        <Col className='fb-750' xs={{ offset: 0, order: 2, size: 2 }}>&#8377; 1738.00</Col>
                    </Row>
                    <Row className='borderDashed pb-1 pt-1'>
                        <Col className='fb-750' xs={{ offset: 8, order: 1, size: 2 }}>Shipping Cost</Col>
                        <Col className='fb-750' xs={{ offset: 0, order: 2, size: 2 }}>&#8377; 300.00</Col>
                    </Row>
                    <Row className='pt-2'>
                        <Col className='fb-750' xs={{ offset: 8, order: 1, size: 2 }}>Grand Total</Col>
                        <Col className='fb-750' xs={{ offset: 0, order: 2, size: 2 }}>&#8377; 2038.00</Col>
                    </Row>
                </ModalBody>
            </Modal>
        </>
    )
}
export default OrderHistory;