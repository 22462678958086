import React, { useState, useEffect } from 'react';
import { notification } from '../../hocs/notification';
import { mainurl, imgurl } from '../../../Mainurl';
import axios from 'axios';
import { FaCheckCircle } from 'react-icons/fa';

import HarvestData from './HarvestData';
import ExtraActivity from './ExtraActivity';
import SoilTest from './SoilTest';

import { Row, Col, Card, Alert, CardHeader, CardBody, Modal, ModalHeader, ModalBody } from 'reactstrap';

const TraceabilityDetail = ({ data, ...props }) => {
    const [loading, setLoading] = useState(false);
    const [report, setReport] = useState('');
    const [viewModal, setViewModal] = useState(false);
    const [modalTitle, setModalTitle] = useState('Details');
    const [extraActList, setExtraActList] = useState('');
    const [modalData, setModalData] = useState('');

    useEffect(() => {
        //console.log(data);
        get_traceability_details();
        get_extra_activity_list();
    }, []);

    const get_traceability_details = () => {

        if (data.phase_detail !== null) {
            let pop_m_p_details = data.phase_detail.map(function (row1, index1) {
                if (true) {
                    let status = "Incomplete";
                    let pop_msrng_id = row1.field_detail.map(function (row2, index2) {
                        if (row2.md_value_ac !== '') {
                            status = "Complete";
                        } else {
                            status = "Incomplete";
                        }
                        return (
                            <tr key={index2}>
                                <td>{row2.m_desc}</td>
                                <td >{row2.md_value_p}</td>
                                <td ><b>{(row2.md_value_ac === '' || row2.md_value_ac === null) ? '' : row2.md_value_ac + " " + row2.uom_desc}</b></td>
                                <td ><b>{(row2.md_date_ac === '' || row2.md_date_ac === null) ? '' : date_format(row2.md_date_ac)}</b></td>
                            </tr>
                        )
                    })
                    let toggleDataHandler = (e) => {
                        if (!e.currentTarget.nextSibling.classList.contains('is-visible')) {
                            const allElements = document.getElementsByClassName('subActivity');
                            for (let i = 0; i < allElements.length; i++) {
                                allElements[i].classList.remove("is-visible");
                            }
                            e.currentTarget.nextSibling.classList.add("is-visible");
                        } else {
                            e.currentTarget.nextSibling.classList.remove("is-visible");
                        }
                    }
                    let tickMark;
                    if (row1.actual_date === null || row1.actual_date === " " || row1.actual_date === undefined) {
                        tickMark = " ";
                    } else {
                        let rDate = new Date(row1.seq_cal_day + " 00:00:00");
                        //let aDate = new Date(row1.actual_date+ " 00:00:00");
                        let aDate = new Date("2022-09-01 00:00:00");
                        if ((rDate - aDate) >= 0) {
                            tickMark = <FaCheckCircle color="green" size={20} />;
                        } else {
                            tickMark = <FaCheckCircle color="red" size={20} />;
                        }
                    }
                    return (
                        <tbody key={index1}>
                            <tr id="activityName" onClick={toggleDataHandler}>
                                <td >{row1.m_desc}</td>
                                <td >{date_format(row1.seq_cal_day)}</td>
                                <td >{status}</td>
                            </tr>
                            <div className="table-responsive subActivity toggle-content">
                                <table id="msrng_field" className="table table-striped">
                                    <thead><tr>
                                        <td>Measuring Sub-field</td>
                                        <td>Recommend Value</td>
                                        <td>Actual Value</td>
                                        <td>Date</td>
                                    </tr></thead>
                                    <tbody>{pop_msrng_id}</tbody>
                                </table>
                            </div>
                        </tbody>
                    )
                }
            });
            setReport(pop_m_p_details);
        } else {
            let msg = <tr><Alert className='mt-2' color="danger"> Traceability details not found!</Alert></tr>
            setReport(msg);
        }
    }

    const get_extra_activity_list = () => {
        let postData = [{ "org_id": sessionStorage.getItem('org_id'), "crop_veriety": data.crop_variety_code, "lang_id": 1, }];
        axios({
            method: 'post',
            url: mainurl + 'Traceability/extra_activity_web',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                let list = response.data.map((row,index) => {
                    return(
                        <Col className="mb-1">
                            <div className='act_box' onClick={() => extra_activity_handler(row.activity_id)}>
                                <img src={imgurl+row.photo} alt="load.." height="40px" />
                                <small className='d-block mt-1'><b>{row.activity_name}</b></small>
                            </div>
                        </Col>
                    )
                })

                setExtraActList(list);
            })
            .catch(error => {
                setLoading(false);
                //let notify = notification({ message: "User list not Found! Try Again", type: 'error' });
                //notify();
            });
    }

    const show_soil_report = () => {
        let list = <SoilTest />
        setModalData(list);
        setViewModal(true);
    }

    const date_format = (val) => {
        let dt = val.split(" ")[0].split('-').reverse().join('-');
        return dt;
    }

    const extra_activity_handler = (act_id) => {

        let postData = [{ "org_id": sessionStorage.getItem('org_id'), "batch_id": data.batch_no, "activity_type": act_id, }];
        axios({
            method: 'post',
            url: mainurl + 'Traceability/trace_extra_activity_view',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                if (response.data === null) {
                    let notify = notification({ message: "No Data Found!", type: 'warn' });
                    notify();
                }
                if (Number(act_id) < 10003) {
                    setModalTitle('Harvest Data');
                    let list = response.data.map((row, index) => {
                        return (
                            {
                                sl: index + 1,
                                h_date: row.activity_date.split('-').reverse().join('-'),
                                name: row.harvest_crop_name,
                                value: row.value + ' ' + row.uom_desc
                            }
                        )
                    });

                    setModalData(<HarvestData h_data={list} />);
                    setViewModal(true);
                }
                if (Number(act_id) === 10006) {
                    setModalTitle('Fertilizer Data');
                    let list = response.data.map((row, index) => {
                        return (
                            {
                                sl: index + 1,
                                h_date: row.activity_date.split('-').reverse().join('-'),
                                name: row.fertilizer_name,
                                value: row.value + ' ' + row.uom_desc
                            }
                        )
                    });

                    setModalData(<HarvestData h_data={list} />);
                    setViewModal(true);
                }
                if (Number(act_id) > 10002 && Number(act_id) !== 10006) {
                    if(Number(act_id) === 10003){
                        setModalTitle('Irrigation Details');
                    }else if (Number(act_id) === 10004){
                        setModalTitle('Weeding Details');
                    }else if (Number(act_id) === 10005){
                        setModalTitle('Inter Culture Details');
                    }else if (Number(act_id) === 10007){
                        setModalTitle('Disease Details');
                    }else if (Number(act_id) === 10008){
                        setModalTitle('Image Details');
                    }else if (Number(act_id) === 10009){
                        setModalTitle('Advisory Details');
                    }
                    
                    let list = response.data.map((row, index) => {
                        return (
                            {
                                sl: index + 1,
                                name: row.activity_name,
                                h_date: row.activity_date.split('-').reverse().join('-')
                            }
                        )
                    });

                    setModalData(<ExtraActivity h_data={list} />);
                    setViewModal(true);
                }
                setLoading(false);
            })
            .catch(error => {
                setLoading(false);
                //let notify = notification({ message: "User list not Found! Try Again", type: 'error' });
                //notify();
            });

    }

    return (
        <>

            <Card className='mb-3' color="primary" outline>
                <CardHeader>Farmer Detail</CardHeader>
                <CardBody>
                    <Row>
                        <Col sm={2} className="mb-1">
                            <small className="text-muted">Farmer Name</small>
                            <h6><b>{data.f_name}</b></h6>
                        </Col>
                        <Col sm={2} className="mb-1">
                            <small className="text-muted">Farmer ID</small>
                            <h6><b>{data.farmer_code}</b></h6>
                        </Col>
                        <Col sm={2} className="mb-1">
                            <small className="text-muted">State</small>
                            <h6><b>{data.state_name}</b></h6>
                        </Col>
                        <Col sm={2} className="mb-1">
                            <small className="text-muted">District</small>
                            <h6><b>{data.dist_name}</b></h6>
                        </Col>
                        <Col sm={2} className="mb-1">
                            <small className="text-muted">Block</small>
                            <h6><b>{data.block_name}</b></h6>
                        </Col>
                        {/*<Col sm={2} className="mb-1">
                                <small className="text-muted">Panchayat</small>
                                <h6><b>{data.pan_id}</b></h6>
                            </Col>*/}
                        <Col sm={2} className="mb-1">
                            <small className="text-muted">Village</small>
                            <h6><b>{data.vill_name}</b></h6>
                        </Col>

                        <Col sm={2} className="mb-1">
                            <small className="text-muted">Season</small>
                            <h6><b>Kharif</b></h6>
                        </Col>
                        <Col sm={2} className="mb-1">
                            <small className="text-muted">Crop</small>
                            <h6><b>{data.crop_variety_name}</b></h6>
                        </Col>
                        <Col sm={2} className="mb-1">
                            <small className="text-muted">Sowing Date</small>
                            <h6><b>{data.pop_start_date.split(" ")[0].split('-').reverse().join('-')}</b></h6>
                        </Col>
                    </Row>
                </CardBody>
            </Card>

            <Card className='mb-3' color="primary" outline>
                <CardHeader>Cultivation Activity</CardHeader>
                <CardBody>
                    <Row>
                        {extraActList}
                    </Row>
                </CardBody>
            </Card>
            <Card className='mb-3' color="primary" outline>
                <CardHeader>Measuring Report</CardHeader>
                <CardBody>
                    <table id="traceabilityReport" className="table table-striped" >
                        <thead >
                            <tr>
                                <td>Measuring Field</td>
                                <td>Recommend Date</td>
                                <td>Status</td>
                            </tr>
                        </thead>
                        {report}
                    </table>
                </CardBody>
            </Card>

            <Modal
                centered
                scrollable
                size="lg"
                isOpen={viewModal}
                toggle={() => setViewModal(!viewModal)}
            >
                <ModalHeader toggle={() => setViewModal(!viewModal)}>
                    {modalTitle}
                </ModalHeader>
                <ModalBody>
                    {modalData}
                </ModalBody>
            </Modal>
        </>
    )
}

export default TraceabilityDetail;