import React, {useEffect, useState} from 'react';
import Avtar from './Avtar'; 
import { Row, Col, Card, CardBody, CardHeader, Button } from 'reactstrap';


const ViewFarmer = ({data, back}) => {
    const [gender, setGender] = useState('');
    const [category, setCategory] = useState('');

    useEffect(()=>{
        if(data.gender === '1'){
            setGender('Male');
        }else{
            setGender('Female');
        }
        if(data.category === '1'){
            setCategory('General');
        }else if(data.category === '2'){
            setCategory('OBC');
        }else if(data.category === '3'){
            setCategory('ST / SC');
        }
    },[]);
    
    return (
        <>
            <Card className='mb-3' color="primary" outline>
                <CardHeader> Farmer Details </CardHeader>
                <CardBody>
                    <Row>
                        <Col md={2}>
                            <Avtar path="" />
                        </Col>
                        <Col md={10}>
                            <Row>
                                <Col md={3} className="mb-2">
                                    <small className="text-muted"> Farmer ID </small>
                                    <h6 className=""> {data.farmer_code} </h6>
                                </Col>
                                <Col md={3} className="mb-2">
                                    <small className="text-muted"> Name </small>
                                    <h6 className=""> {data.f_name} </h6>
                                </Col>
                                <Col md={3} className="mb-2">
                                    <small className="text-muted"> Name Hindi </small>
                                    <h6 className=""> {data.f_name_ne} </h6>
                                </Col>
                                <Col md={3} className="mb-2">
                                    <small className="text-muted"> Spouse Name </small>
                                    <h6 className=""> {data.f_spouse_name} </h6>
                                </Col>
                                <Col md={3} className="mb-2">
                                    <small className="text-muted"> Age </small>
                                    <h6 className=""> {data.age} </h6>
                                </Col>
                                <Col md={3} className="mb-2">
                                    <small className="text-muted"> Category </small>
                                    <h6 className=""> {category} </h6>
                                </Col>
                                <Col md={3} className="mb-2">
                                    <small className="text-muted"> Gender </small>
                                    <h6 className=""> {gender} </h6>
                                </Col>
                                <Col md={3} className="mb-2">
                                    <small className="text-muted"> Mobile Number </small>
                                    <h6 className=""> {data.mobile_no +" / "+ data.mobile_no_2} </h6>
                                </Col>
                                <Col md={3} className="mb-2">
                                    <small className="text-muted"> State </small>
                                    <h6 className=""> {data.state_name} </h6>
                                </Col>
                                <Col md={3} className="mb-2">
                                    <small className="text-muted"> District </small>
                                    <h6 className=""> {data.district_name} </h6>
                                </Col>
                                <Col md={3} className="mb-2">
                                    <small className="text-muted"> Block </small>
                                    <h6 className=""> {data.block_name} </h6>
                                </Col>
                                {/*<Col md={3} className="mb-2">
                                    <small className="text-muted"> Panchayat </small>
                                    <h6 className=""> {data.pan_id} </h6>
                                </Col>*/}
                                <Col md={3} className="mb-2">
                                    <small className="text-muted"> Village </small>
                                    <h6 className=""> {data.village_name} </h6>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
            <Row>
                <Col md={5}>
                    <Card className='mb-3 h-100' color="primary" outline>
                        <CardHeader> Cropping Pattern </CardHeader>
                        <CardBody>
                            <Row className='mb-2'>
                                <Col xs={3}>
                                    <img className='me-1' src={require('../../../assets/img/winter.png')} alt=".." />
                                    <b>Rabi</b>
                                </Col>
                                <Col xs={9}>Cowpea, Basella, Spinach, cabbage</Col>
                            </Row>
                            <Row className='mb-2'>
                                <Col xs={3}>
                                    <img className='me-1' src={require('../../../assets/img/summer.png')} alt=".." />
                                    <b>Garma</b>
                                </Col>
                                <Col xs={9}>Bitter Gourd, Ridge Gourd, Spinach</Col>
                            </Row>
                            <Row className='mb-2'>
                                <Col xs={3}>
                                    <img className='me-1' src={require('../../../assets/img/rainy.png')} alt=".." />
                                    <b>Kharif</b>
                                </Col>
                                <Col xs={9}>Chilli, Tomato, Spinach, Amaranthus</Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            
                <Col md={4}>
                    <Card className='mb-3 h-100' color="primary" outline>
                        <CardHeader> Source of irrigation </CardHeader>
                        <CardBody>
                            <Row>
                                <Col className='text-center'>
                                    <img src={require('../../../assets/img/well.png')} alt="load." />
                                    <div className='mt-1'><b>Well</b></div>
                                </Col>
                                <Col className='text-center'>
                                    <img src={require('../../../assets/img/pond.png')} alt="load." />
                                    <div className='mt-1'><b>Pond</b></div>
                                </Col>
                                <Col className='text-center'>
                                    <img src={require('../../../assets/img/canel.png')} alt="load." />
                                    <div className='mt-1'><b>Cannel</b></div>
                                </Col>
                                <Col className='text-center'>
                                    <img src={require('../../../assets/img/rain.png')} alt="load." />
                                    <div className='mt-1'><b>Rainfed</b></div>
                                </Col>
                                <Col className='text-center'>
                                    <img src={require('../../../assets/img/drip.png')} alt="load." />
                                    <div className='mt-1'><b>Drip</b></div>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            
                <Col md={3}>
                    <Card className='mb-3 h-100' color="primary" outline>
                        <CardHeader> Land Details (In Acre) </CardHeader>
                        <CardBody>
                            <Row>
                                <Col xs={6}><b>Don</b></Col>
                                <Col xs={6}>2.5</Col>
                            </Row>
                            <Row>
                                <Col xs={6}><b>Baid</b></Col>
                                <Col xs={6}>0.2</Col>
                            </Row>
                            <Row>
                                <Col xs={6}><b>Tanr</b></Col>
                                <Col xs={6}>0.6</Col>
                            </Row>
                            <Row>
                                <Col xs={6}><b>Kanali</b></Col>
                                <Col xs={6}>0.0</Col>
                            </Row>
                            <Row>
                                <Col xs={6}><b>Home Stead</b></Col>
                                <Col xs={6}>0.5</Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
                
            <div className='text-end mt-3'>
                <Button className='text-white' color='primary' size="sm" onClick={back}> Back </Button>
            </div>
        </>
    )
}

export default ViewFarmer;