import React, {useState, useEffect} from 'react';

const Avtar = ({path}) => {
    const [pic, setPic] = useState('');

    useEffect(() => {
        if(path === ""){
            setPic(require('./../../../assets/img/avtar.png'));
        }else{
            setPic(path);
        }
    }, []);

    return(
        <div className='avtar'>
            <img className='img-fluid' src={pic} alt="Framer Pic" />
        </div>
    )
}
export default Avtar;