import React, { useState, useEffect } from 'react';
import Header from '../../component/layout/Header';
import Footer from '../../component/layout/Footer';
import Page from '../../component/utility/Page';
import DataTable from 'react-data-table-component';
import { custTableStyles } from '../../style/color';

import { Row, Col, Card, CardHeader, CardBody, Form, Input, Label, InputGroup, InputGroupText, Spinner, Button } from 'reactstrap';
import {FaHeading, FaEdit, FaClipboardList, FaPlusCircle} from 'react-icons/fa';
import {MdDeleteForever, } from 'react-icons/md';

const Language = () => {
    const [hindiNameLoading, sethindiNameLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [tableData, setTableData] = useState('');

    const [code, setCode] = useState('');
    const [name, setName] = useState('');
    const [nameHin, setNameHin] = useState('');
    
    const [mode, setMode] = useState('1');
        

    const language_list = () => {
        const list = [
            {
                ids: 1,
                name: 'English',
                name_h: 'English',
                action: <div>
                            <FaEdit className='me-3 cursor' color="#006600" size={20} />
                            <MdDeleteForever className='cursor' color="#f70000" size={20} />
                        </div>
            },
            {
                ids: 2,
                name: 'Hindi',
                name_h: 'हिन्दी',
                action: <div>
                            <FaEdit className='me-3 cursor' color="#006600" size={20} />
                            <MdDeleteForever className='cursor' color="#f70000" size={20} />
                        </div>
            },
            {
                ids: 3,
                name: 'Bengali',
                name_h: 'বাংলা',
                action: <div>
                            <FaEdit className='me-3 cursor' color="#006600" size={20} />
                            <MdDeleteForever className='cursor' color="#f70000" size={20} />
                        </div>
            },
            {
                ids: 4,
                name: 'Oriya',
                name_h: 'ଓଡ଼ିଆ',
                action: <div>
                            <FaEdit className='me-3 cursor' color="#006600" size={20} />
                            <MdDeleteForever className='cursor' color="#f70000" size={20} />
                        </div>
            },
            {
                ids: 5,
                name: 'Marathi',
                name_h: 'मराठी',
                action: <div>
                            <FaEdit className='me-3 cursor' color="#006600" size={20} />
                            <MdDeleteForever className='cursor' color="#f70000" size={20} />
                        </div>
            },
        ]

        setTableData(list);
    }

    const add_handler = (e) => {

    }

    const translate_text_handler = (e) => {

    }

    return (
        <>
            <Header />
            <Page title='Languages' breadcrumbs='Home / Languages'>
                <Row className='p-3'>
                    
                    {mode === "1" && 
                    <Col md={{offset: 3, order: 2, size: 6}}>
                        <Card className='mb-3' color="primary" outline>
                            <CardBody>
                                <Row>
                                <Col md={6}>
                                    <Button className='text-white' color='primary' size="sm" onClick={language_list} block><FaClipboardList className='me-1' size={20} /> Get Language List </Button>
                                </Col>
                                <Col md={6}>
                                    <Button className='text-white' color='primary' size="sm" onClick={()=>setMode("2")} block><FaPlusCircle className='me-1' size={20} /> Add New Language </Button>
                                </Col>
                                </Row>
                            </CardBody>
                        </Card>
                        <Card className='' color="primary" outline>
                            <CardBody>
                            <DataTable
                                title=""
                                customStyles={custTableStyles}
                                columns={[
                                    {
                                    name: 'Id',
                                    selector: row=>row.ids,
                                    sortable: true,
                                    width: '100px'
                                    },
                                    {
                                    name: 'Language Name',
                                    selector: row=>row.name,
                                    sortable: false,
                                    },
                                    {
                                    name: 'Language Name Hindi',
                                    selector: row=>row.name_h,
                                    sortable: false,
                                    },
                                    {
                                    name: 'Action',
                                    selector: row=>row.action,
                                    sortable: false,
                                    },
                                ]}
                                data={tableData}
                                highlightOnHover
                                progressPending={loading}
                                />
                            </CardBody>
                        </Card>
                    </Col>
                    }
                    {mode === "2" && 
                        <Col xs={12}>
                        <Card  className='mb-3' color="primary" outline>
                            <CardHeader>Add New Language </CardHeader>
                            <CardBody>
                                <Form onSubmit={add_handler}>
                                    <Row>
                                        <Col md={2} className="mb-2">
                                            <Label for="code"> Language Code</Label>
                                            <Input id="code" type='text' bsSize="sm" value={code} onChange={(e) => setCode(e.target.value)} required />
                                        </Col>
                                        <Col md={2} className="mb-2">
                                            <Label for="name">  Language Name</Label>
                                            <Input id="name" type='text' bsSize="sm" value={name} onChange={(e) => setName(e.target.value)} required />
                                        </Col>
                                        <Col md={2} className="mb-2">
                                            <Label for="hName">  Language Name Hindi</Label>
                                            <InputGroup size="sm">
                                                <Input id="hName" type='text' value={nameHin} bsSize="sm" readOnly />
                                                <InputGroupText className='bg-primary cursor' onClick={!hindiNameLoading ? translate_text_handler : undefined}>
                                                    {hindiNameLoading ? <Spinner color="secondary" size="sm">Loading...</Spinner> : <FaHeading color="#fff" />}
                                                </InputGroupText>
                                            </InputGroup>
                                        </Col>
                                        
                                        <Col md={2} className="mb-2">
                                            <Button className='text-white mt-4' color='primary' size="sm" block> Submit </Button>
                                        </Col>
                                    </Row>
                                </Form>    
                            </CardBody>
                        </Card>
                        <div className='text-end'>
                            <Button className='text-white' color='primary' size="sm" onClick={() => setMode("1")}> Back </Button>
                        </div>
                    </Col>
                    }
                </Row>

            </Page>
            <Footer />

        </>
    )

}

export default Language;