import React from 'react';
import ReactDOM from "react-dom/client";
import App from './App';
import { HashRouter } from "react-router-dom";
import { ThemeProvider, createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#4d8d6e',
    },
    secondary: {
      main: '#FFC000',
    },
  },
});

/*import {Grommet} from 'grommet';
const customTheme = { 
  global: {
    colors: {
      // Overriding existing grommet colors
      brand: "#4d8d6e",
    },
    font: {
      family: 'Roboto, sans-serif',
      size: '0.8rem',
      height: '20px'
    },
  }
};*/

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <HashRouter>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
  </HashRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
