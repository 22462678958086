import React from 'react';

const Page = ({title, breadcrumbs, children, ...props}) => {
    return (
        <div style={{minHeight:"530px", padding:"0 60px"}}>
            {title.length > 0 &&
                <div className='pt-3 d-flex'>
                    <h5 className='m-0 ps-3'>{title}</h5>
                    <div className='breadcrumbs'>{breadcrumbs}</div>
                </div>
            }
            {children}
        </div>
    )
}

export default Page;