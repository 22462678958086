// const availableColors = [
//   'blue',
//   'indigo',
//   'purple',
//   'pink',
//   'red',
//   'orange',
//   'yellow',
//   'green',
//   'teal',
//   'cyan',
//   'white',
//   'gray',
//   'gray-dark',
//   'primary',
//   'secondary',
//   'success',
//   'info',
//   'warning',
//   'danger',
//   'light',
//   'dark',
// ];

export const getColor = (availableColor = 'primary') => {
    if (typeof window === 'undefined') {
      return null;
    }
  
    const color = window
      .getComputedStyle(document.documentElement)
      .getPropertyValue(`--${availableColor}`);
  
    return color;
  };
  
  export const getThemeColors = () => [
    'primary',
    'secondary',
    'success',
    'info',
    'warning',
    'danger',
  ];

  export const custTableStyles = {
    rows: {
        style: {
            minHeight: '40px', // override the row height
        },
    },
    headCells: {
        style: {
            paddingLeft: '8px', // override the cell padding for head cells
            paddingRight: '8px',
            fontSize: '0.9rem',
            fontWeight: 'bold',
            minHeight: '40px',
            color: "#212529"
        },
    },
    cells: {
        style: {
            paddingLeft: '8px', // override the cell padding for data cells
            paddingRight: '8px',
        },
    },
};
  