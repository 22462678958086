import React from 'react';

import { Row, Col, } from 'reactstrap';

import {MdDeleteForever } from 'react-icons/md';

const CartItem = ({data, ...props}) => {
    return(
        <Row className='pt-1 pb-1 borderBottomSecondary'>
            <Col className='align-items-center d-flex' xs={2}>{data.name}</Col>
            <Col className='align-items-center d-flex' xs={1}><img height="30px" alt="Sample" src={require("../../../assets/img/cropImg/"+data.img+".png")} /></Col>
            <Col className='align-items-center d-flex' xs={2}>{data.unit}</Col>
            <Col className='align-items-center d-flex' xs={2}>&#8377; {data.price}</Col>
            <Col className='align-items-center d-flex' xs={1}>{data.quantity}</Col>
            <Col className='align-items-center d-flex' xs={2}>&#8377; {data.total}</Col>
            <Col className='align-items-center d-flex' xs={2}><MdDeleteForever className='cursor' color="#f70000" size={20} /></Col>
        </Row>
    )
}

export default CartItem;