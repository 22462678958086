import React, {useState} from 'react';

import {FaHeading} from 'react-icons/fa';
import { Row, Col, Card, CardBody, CardFooter, CardHeader, Form, Input, Label, InputGroup, InputGroupText, Spinner, Button } from 'reactstrap';

const AddEmployee = ({back}) => {
    const [hindiNameLoading, sethindiNameLoading] = useState(false);
    const [code, setCode] = useState('');
    const [name, setName] = useState('');
    const [nameHin, setNameHin] = useState('');
    const [mobileNo, setMobileNo] = useState('');
    const [district, setDistrict] = useState('');
    const [block, setBlock] = useState('');
    const [panchayat, setPanchayat] = useState('');
    const [village, setVillage] = useState('');

    const add_handler = () => {

    }

    const translate_text_handler = () => {

    }

    const getBlockList = (e) => {

    }

    const getPanchayatList = (e) => {

    }

    const getVillageList = (e) => {

    }

    return (
        <Card className='mb-3' color="primary" outline>
            <CardHeader> Add New Employee </CardHeader>
            <CardBody>
                <Form onSubmit={add_handler}>
                    <Row>
                        <Col md={2} className="mb-2">
                            <Label for="code"> Employee Code</Label>
                            <Input id="code" type='text' bsSize="sm" value={code} onChange={(e) => setCode(e.target.value)} required />
                        </Col>
                        <Col md={2} className="mb-2">
                            <Label for="name">  Employee Name</Label>
                            <Input id="name" type='text' bsSize="sm" value={name} onChange={(e) => setName(e.target.value)} required />
                        </Col>
                        <Col md={2} className="mb-2">
                            <Label for="hName">  Employee Name Hindi</Label>
                            <InputGroup size="sm">
                                <Input id="hName" type='text' value={nameHin} bsSize="sm" readOnly />
                                <InputGroupText className='bg-primary cursor' onClick={!hindiNameLoading ? translate_text_handler : undefined}>
                                    {hindiNameLoading ? <Spinner color="secondary" size="sm">Loading...</Spinner> : <FaHeading color="#fff" />}
                                </InputGroupText>
                            </InputGroup>
                        </Col>

                        <Col md={2} className="mb-2">
                            <Label for="name">District</Label>
                            <Input id="district" bsSize="sm" type="select" value={district} onChange={(e) => getBlockList(e)}>
                                <option value="0"> --Select District--</option>
                                <option value="1"> Ranchi </option>

                            </Input>
                        </Col>
                        <Col xs={2} className="mb-2">
                            <Label for="block"> Block </Label>
                            <Input id="block" bsSize="sm" type="select" value={block} onChange={(e) => getPanchayatList(e)}>
                                <option value="0"> --Select Block--</option>
                                <option value="1"> Ranchi </option>
                            </Input>
                        </Col>

                        <Col xs={2} className="mb-2">
                            <Label for="village"> Village </Label>
                            <Input id="village" bsSize="sm" type="select" value={village} onChange={(e) => setVillage(e.target.value)} >
                                <option value="0"> --Select Village--</option>
                                <option value="1"> Ranchi </option>
                            </Input>
                        </Col>
                        <hr />
                        <Col md={2} className="mb-2">
                            <Button className='text-white' color='primary' size="sm" block> Submit </Button>
                        </Col>
                    </Row>
                </Form>
            </CardBody>
            <CardFooter className='text-end'>
                <Button className='text-white' color='primary' size="sm" onClick={back}> Back </Button>
            </CardFooter>
        </Card>
    )
}

export default AddEmployee;