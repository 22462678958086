import React, {useState, useEffect} from 'react';
import { notification } from '../../hocs/notification';
import axios from 'axios';
import { mainurl } from '../../../Mainurl';

import {FaHeading} from 'react-icons/fa';
import { Row, Col, Card, CardBody, CardFooter, CardHeader, Form, FormGroup, Input, Label, InputGroup, InputGroupText, Spinner, Button } from 'reactstrap';

const AddUser = ({back, reset}) => {
    const [hindiNameLoading, sethindiNameLoading] = useState(false);
    const [name, setName] = useState('');
    const [nameHin, setNameHin] = useState('');
    const [mobileNo, setMobileNo] = useState('');
    const [role, setRole] = useState('');
    const [password, setPassword] = useState('');
    const [email, setEmail] = useState('');
    const [cfmPassword, setCfmPassword] = useState('');
    const [passwordValid, setPasswordValid] = useState(false);

    const [roleOpt, setRoleOpt] = useState('');

    useEffect(()=>{
        let postData = [{"org_id": sessionStorage.getItem('org_id')}];
        axios({
            method: 'post',
            url: mainurl + 'Master/box_role_list',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                let list = response.data.map((row, index) => {
                    return(
                        <option key={index} value={row.role_code}> {row.role_desc} </option>
                    )
                });

                setRoleOpt(list);
            })
            .catch(error => {
                //let notify = notification({ message: "User list not Found! Try Again", type: 'error' });
                //notify();
            });
    }, [])

    const add_handler = (e) => {
        e.preventDefault();
        let postData = [ {
            "org_id": sessionStorage.getItem('org_id') ,
            "user_id": '10001',
            "user_name": name,
            "user_name_ne": nameHin,
            "user_email": email,
            "user_phone": mobileNo,
            "role_code": role,
            "password": password,
        }];

        if(passwordValid && role > 0){
            axios({
                method: 'post',
                url: mainurl + 'Master/box_user_add',
                data: postData,
                headers: { 'Content-Type': 'application/JSON', }
            })
            .then(function (response) {
                let user_id = response.data[0].retcode;
                let notify = notification({ message: "User added Successfully. User Id is = "+user_id+".", type: 'info', time:false, position:'center' });
                notify();
                reset();
            })
            .catch(error => {
                let notify = notification({ message: "Submit failed! Try Again", type: 'error',  time:true });
                notify();
            });
        }else{
            let notify = notification({ message: "Password not match! or User Role not selected.", type: 'warn', time:true });
            notify();
        }

    }

    const translate_text_handler = () => {

    }

    const check_valid = (pass) => {
        if(password === pass){
            setPasswordValid(true);
        }else{
            setPasswordValid(false);
        }
    }


    return (
        <>
        <Card className='mb-3' color="primary" outline>
            <CardHeader> Add New User </CardHeader>
            <CardBody>
                <Form onSubmit={add_handler}>
                    <Row>
                        <FormGroup row>
                            <Label for="name" sm={2} > Name* </Label>
                            <Col sm={4}>
                                <Input id="name" type='text' bsSize="sm" onKeyPress={(e) => !/^[a-zA-Z\s]*$/.test(e.key) && e.preventDefault()} value={name} onChange={(e) => setName(e.target.value)} required />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label for="hName" sm={2} > Name Hindi* </Label>
                            <Col sm={4}>
                                <InputGroup size="sm">
                                    <Input id="hName" type='text' value={nameHin} bsSize="sm" onChange={(e)=> setNameHin(e.target.value)} />
                                    <InputGroupText className='bg-primary cursor' onClick={!hindiNameLoading ? translate_text_handler : undefined}>
                                        {hindiNameLoading ? <Spinner color="secondary" size="sm">Loading...</Spinner> : <FaHeading color="#fff" />}
                                    </InputGroupText>
                                </InputGroup>
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label for="email" sm={2} > Email </Label>
                            <Col sm={4}>
                                <Input id="email" type='text' bsSize="sm" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Email" />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label for="mobileNo" sm={2} > Mobile Number*</Label>
                            <Col sm={2}>
                                <Input id="mobileNo" type='text' bsSize="sm" maxLength={10} pattern="[0-9]*" onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()} value={mobileNo} onChange={(e) => setMobileNo(e.target.value)} placeholder="Mobile Number" required />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label for="role" sm={2} > User Role* </Label>
                            <Col sm={4}>
                                <Input id="role" bsSize="sm" type="select" value={role} onChange={(e) => setRole(e.target.value)} >
                                    <option value="0"> --Select Role--</option>
                                    {roleOpt}
                                </Input>
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label for="password" sm={2} > Password* </Label>
                            <Col sm={2}>
                                <Input id="password" type='password' bsSize="sm" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Password" required />
                            </Col>
                            <Col sm={2}>
                                <Input id="cpassword" type='password' bsSize="sm" onChange={(e) => check_valid(e.target.value)} valid={passwordValid} invalid={!passwordValid} placeholder="Confirm Password" required />
                            </Col>
                        </FormGroup>
                        
                        <hr />
                        <Col md={2} className="mb-2">
                            <Button className='text-white' color='primary' size="sm" block> Submit </Button>
                        </Col>
                    </Row>
                </Form>
            </CardBody>  
        </Card>
        <div className='text-end mt-3'>
            <Button className='text-white' color='primary' size="sm" onClick={back}> Back </Button>
        </div>
        </>
    )
}

export default AddUser;