import React, { useState, useEffect } from 'react';
import Header from '../../component/layout/Header';
import Footer from '../../component/layout/Footer';
import Page from '../../component/utility/Page';
import DataTable from 'react-data-table-component';
import { custTableStyles } from '../../style/color';
import axios from 'axios';
import { mainurl } from '../../Mainurl';

import { Row, Col, Card, CardHeader, CardBody, Form, Input, InputGroup, InputGroupText, Button, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { FaEye, FaSearch, } from 'react-icons/fa';

const Training = () => {
    const [mode, setMode] = useState("1");
    const [loading, setLoading] = useState(false);
    const [tableData, setTableData] = useState('');

    const [viewModal, setViewModal] = useState(false);
    const [modalData, setModalData] = useState('');

    const get_training_list = (e) => {
        e.preventDefault();

        let postData = [{"org_id": sessionStorage.getItem('org_id'), "user_id": "101", "type": "1", "lang_id": 1}];
        axios({
            method: 'post',
            url: mainurl + 'Activity/event_view',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        })
        .then(function (response) {
            let list = response.data.map((row, index) => {
                return {
                        sl: index + 1,
                        date: row.event_date.split(" ")[0].split('-').reverse().join('-'),
                        location: row.event_loc,
                        trainer: row.user_name,
                        subject: row.subject_desc,
                        farmer_count: row.attendance,
                        action: <FaEye className='me-3 cursor' color="#FFC000" size={20} onClick={()=>farmer_list_handler(row.farmer_detail)} />
                    }
            });

            setTableData(list);
        })
        .catch(error => {
            setLoading(false);
            //let notify = notification({ message: "User list not Found! Try Again", type: 'error' });
            //notify();
        }); 
    }

    const farmer_list_handler = (dtl) => {

        const list = dtl.map((row, index) => {
            return{
                sl: index + 1,
                farmer_code: row.attendee_id,
                farmer_name: row.f_name + " (" + row.f_name_ne + ")"
            }    
        })

        setModalData(list);
        setViewModal(true);
    }


    return (
        <>
            <Header />
            <Page title='Training' breadcrumbs='Home / Training'>
                <Row className='p-3'>
                    {mode === "1" &&
                        <Col xs={12}>
                            <Card className='mb-3' color="primary" outline>
                                <CardBody>
                                    <Form onSubmit={get_training_list}>
                                        <Row>
                                            <Col md={2} className="mb-2">
                                                <Button className='text-white' color='primary' size="sm" block> Get Training List </Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                </CardBody>
                            </Card>
                            <Card className='' color="primary" outline>
                                <CardHeader>Training List <small className='text-muted'> </small></CardHeader>
                                <Col sm={{ offset: 6, size: 3 }} style={{ position: 'absolute', top: '2px', right: '2px' }}>
                                    <InputGroup size="sm">
                                        <InputGroupText> <FaSearch /> </InputGroupText>
                                        <Input type="search" placeholder='Search' />
                                    </InputGroup>
                                </Col>
                                <CardBody>
                                    <DataTable
                                        title=""
                                        customStyles={custTableStyles}
                                        columns={[
                                            {
                                                name: '#',
                                                selector: row => row.sl,
                                                sortable: true,
                                                width: '80px'
                                            },
                                            {
                                                name: 'Training Date',
                                                selector: row => row.date,
                                                sortable: false,
                                            },
                                            {
                                                name: 'Trainer',
                                                selector: row => row.trainer,
                                                sortable: false,
                                            },
                                            {
                                                name: 'Training Subject',
                                                selector: row => row.subject,
                                                sortable: false,
                                                width:"20%"
                                            },
                                            {
                                                name: 'Attendance',
                                                selector: row => row.farmer_count,
                                                sortable: false,
                                            },
                                            {
                                                name: 'See Attendance',
                                                selector: row => row.action,
                                                sortable: false,
                                            },
                                        ]}
                                        data={tableData}
                                        highlightOnHover
                                        progressPending={loading}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    }

                </Row>

            </Page>
            <Modal
                centered
                scrollable
                size="md"
                isOpen={viewModal}
                toggle={() => setViewModal(!viewModal)}
            >
                <ModalHeader toggle={() => setViewModal(!viewModal)}>
                    Farmer Details
                </ModalHeader>
                <ModalBody>
                    <DataTable
                        title=""
                        customStyles={custTableStyles}
                        columns={[
                            {
                                name: '#',
                                selector: row => row.sl,
                                sortable: true,
                                width: '80px'
                            },
                            {
                                name: 'Farmer Code',
                                selector: row => row.farmer_code,
                                sortable: false,
                            },
                            {
                                name: 'Farmer Name',
                                selector: row => row.farmer_name,
                                sortable: false,
                                width: "50%"
                            },
                        ]}
                        data={modalData}
                        highlightOnHover
                        pagination
                        progressPending={loading}
                    />
                </ModalBody>
            </Modal>

            <Footer />

        </>
    )

}

export default Training;