import React, { useState, useEffect } from 'react';
import { notification } from '../../hocs/notification';

import DataTable from 'react-data-table-component';
import { custTableStyles } from '../../../style/color';

import axios from 'axios';
import { mainurl } from '../../../Mainurl';
import Select from 'react-select';
import { Row, Col, Card, CardBody, CardHeader, Form, FormGroup, Input, Label, Button, Modal, ModalBody, ModalHeader } from 'reactstrap';

import {MdDeleteForever} from 'react-icons/md';

const AssignUserView = ({ user, back, ...props }) => {
    const [loading, setLoading] = useState(false);

    const [state, setState] = useState('');
    const [district, setDistrict] = useState('');
    const [block, setBlock] = useState('');
    const [village, setVillage] = useState('');

    const [stateOpt, setStateOpt] = useState('');
    const [districtOpt, setDistrictOpt] = useState('');
    const [blockOpt, setBlockOpt] = useState('');
    const [villageOpt, setVillageOpt] = useState('');

    const [tableData, setTableData] = useState('');

    const [password, setPassword] = useState('');
    const [cfmPassword, setCfmPassword] = useState('');
    const [passwordValid, setPasswordValid] = useState(false);
    const [viewModal, setViewModal] = useState(false);

    useEffect(() => {
        getAllocationList();
        get_state_list();
    }, [])

    const delete_handler = (id) => {
        console.log(id);
        let postData = [{ "map_id": id }];
        axios({
            method: 'post',
            url: mainurl + 'Master/user_allocation_delete',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                let notify = notification({ message: "Deleted Successfully.", type: 'success' });
                notify();
                getAllocationList();
            })
            .catch(error => {
                //setLoading(false);
                let notify = notification({ message: "submit Failed! Try Again", type: 'error' });
                notify();
            });
    }
    const reset_password_handler = (e) => {
        e.preventDefault();
        if(passwordValid){

            let postData = [{ "user_id": user.user_code, "password": password }];
            axios({
                method: 'post',
                url: mainurl + 'Master/user_reset_password',
                data: postData,
                headers: { 'Content-Type': 'application/JSON', }
            })
                .then(function (response) {
                    let notify = notification({ message: "Password change Successfully.", type: 'success' });
                    notify();
                    setViewModal(false);
                    setPassword('');
                    setPasswordValid(false);
                })
                .catch(error => {
                    //setLoading(false);
                    let notify = notification({ message: "submit Failed! Try Again", type: 'error' });
                    notify();
                });
        }else{
            let notify = notification({ message: "Password and confirm password does not match!", type: 'warn' });
            notify();
        }
        
    }

    const getAllocationList = () => {
        let postData = [{ "org_id": sessionStorage.getItem('org_id'), "user_id": user.user_code }];
        axios({
            method: 'post',
            url: mainurl + 'Master/user_allocation_view',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                if(response.data.length > 0 ){

                    let list = response.data.map((row, index) => {
                        return ({
                            ids: index + 1,
                            district: row.district_name,
                            block: row.block_name,
                            village: row.village_name,
                            action: <div><MdDeleteForever className='cursor' color="#f70000" size={20} onClick={()=>delete_handler(row.user_map_id)} /></div>
                        })
                    });
                    setTableData(list);
                }else{
                    let notify = notification({ message: "Allocation list not Found!", type: 'warn' });
                    notify();
                    setTableData('');
                }

                
            })
            .catch(error => {
                //setLoading(false);
                let notify = notification({ message: "Allocation list not Found! Try Again", type: 'error' });
                notify();
            });
    }


    const get_state_list = (e) => {

        let postData = [{ "country_code": "1" }]
        axios({
            method: 'post',
            url: mainurl + 'Master/get_state_list',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                let list = response.data.map((row, index) => {
                    return (
                        <option value={row.state_code}> {row.state_name}</option>
                    )
                });

                setStateOpt(list);
            })
            .catch(error => {
                setLoading(false);
                //let notify = notification({ message: "User list not Found! Try Again", type: 'error' });
                //notify();
            });
    }

    const get_dist_list = (e) => {
        setState(e.target.value);
        let postData = [{ "state_code": e.target.value }]
        axios({
            method: 'post',
            url: mainurl + 'Master/get_dist_list',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                let list = response.data.map((row, index) => {
                    return (
                        <option value={row.district_code}> {row.district_name}</option>
                    )
                });

                setDistrictOpt(list);
            })
            .catch(error => {
                setLoading(false);
                //let notify = notification({ message: "User list not Found! Try Again", type: 'error' });
                //notify();
            });
    }

    const get_block_list = (e) => {
        setDistrict(e.target.value);
        let postData = [{ "dist_code": e.target.value }]
        axios({
            method: 'post',
            url: mainurl + 'Master/get_block_list',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                let list = response.data.map((row, index) => {
                    return (
                        <option value={row.block_code}> {row.block_name}</option>
                    )
                });

                setBlockOpt(list);
            })
            .catch(error => {
                setLoading(false);
                //let notify = notification({ message: "User list not Found! Try Again", type: 'error' });
                //notify();
            });
    }

    const get_village_list = (e) => {
        setBlock(e.target.value);

        let postData = [{ "block_code": e.target.value }]
        axios({
            method: 'post',
            url: mainurl + 'Master/get_village_list',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                let list = response.data.map((row, index) => {
                    return (
                        { value: row.village_code, label: row.village_name }
                    )
                });

                setVillageOpt(list);
            })
            .catch(error => {
                setLoading(false);
                //let notify = notification({ message: "User list not Found! Try Again", type: 'error' });
                //notify();
            });
    }

    const assign_handler = (e) => {
        e.preventDefault();
        console.log(village);
        let postData = [{
            "org_id": sessionStorage.getItem('org_id'),
            "user_id": user.user_code,
            "state_id": state,
            "district_id": district,
            "block_id": block,
            "panchayat_id": 0,
            "village_list": village
        }];

        axios({
            method: 'post',
            url: mainurl + 'Master/user_allocation',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                if (response.data[0].json_status === "success") {
                    let notify = notification({ message: "Village assign successfully.", type: 'success' });
                    notify();
                    getAllocationList();
                } else {
                    let notify = notification({ message: "Submit failed!", type: 'error' });
                    notify();
                }
            })
            .catch(error => {
                setLoading(false);
                //let notify = notification({ message: "User list not Found! Try Again", type: 'error' });
                //notify();
            });
    }

    const select_vill_handler = (opt) => {
        let list = [];
        opt.map(row => {
            list.push(row.value);
        })
        console.log(list);
        setVillage(list);
    }

    const check_valid = (pass) => {
        if(password === pass){
            setPasswordValid(true);
        }else{
            setPasswordValid(false);
        }
    }

    return (
        <>
            <Card className='mb-3' color="primary" outline>
                <CardHeader> User Details</CardHeader>
                <CardBody>
                    <div className='mb-2'><b>User Name: </b> {user.user_name +" ("+ user.user_name_ne +")"}</div>
                    <div className='mb-2'><b>User Id: </b> {user.user_code}</div>
                    <div className='mb-2'><b>Mobile No.:</b> {user.user_phone}</div>
                    <Button className='text-white' color='primary' size="sm" onClick={()=>setViewModal(true)}> Reset Password </Button> 
                </CardBody>
            </Card>
            <Card className='mb-3' color="primary" outline>
                <CardHeader> Assigned village</CardHeader>
                <CardBody>
                    <DataTable
                        title=""
                        customStyles={custTableStyles}
                        columns={[
                            {
                                name: '#',
                                selector: row => row.ids,
                                sortable: true,
                                width: '80px'
                            },
                            {
                                name: 'District',
                                selector: row => row.district,
                                sortable: false,
                            },
                            {
                                name: 'Block',
                                selector: row => row.block,
                                sortable: false,
                            },
                            {
                                name: 'Village',
                                selector: row => row.village,
                                sortable: false,
                            },
                            {
                                name: 'Action',
                                selector: row => row.action,
                                sortable: false,
                            },
                        ]}
                        data={tableData}
                        highlightOnHover
                        progressPending={loading}
                    />
                    
                    </CardBody>
                </Card>    
                <Card className='mb-3' color="primary" outline>
                    <CardHeader> Assign User with New village</CardHeader>
                    <CardBody>
                    <Form onSubmit={assign_handler}>
                    <Row>
                        <FormGroup row>
                            <Label for="state" sm={2} > State </Label>
                            <Col sm={4}>
                                <Input id="state" bsSize="sm" type="select" value={state} onChange={get_dist_list}>
                                    <option value="0"> --Select State--</option>
                                    {stateOpt}
                                </Input>
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label for="district" sm={2} > District </Label>
                            <Col sm={4}>
                                <Input id="district" bsSize="sm" type="select" value={district} onChange={get_block_list}>
                                    <option value="0"> --Select District--</option>
                                    {districtOpt}
                                </Input>
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label for="block" sm={2} > Block </Label>
                            <Col sm={4}>
                                <Input id="block" bsSize="sm" type="select" value={block} onChange={get_village_list}>
                                    <option value="0"> --Select Block--</option>
                                    {blockOpt}
                                </Input>
                            </Col>
                        </FormGroup>
                        
                        <FormGroup row>
                            <Label for="village" sm={2} > Village </Label>
                            <Col sm={4}>
                                <Select closeMenuOnSelect={false} isMulti
                                    options={villageOpt}
                                    onChange={select_vill_handler}
                                />
                            </Col>
                        </FormGroup>
                        
                        
                        <hr />
                        <Col md={2} className="mb-2">
                            <Button className='text-white' color='primary' size="sm" block> Assigned </Button>
                        </Col>
                    </Row>
                </Form>
                </CardBody>
            </Card>
            <div className='text-end mt-3'>
                <Button className='text-white' color='primary' size="sm" onClick={back}> Back </Button>
            </div>

            <Modal
                centered
                scrollable
                size="md"
                isOpen={viewModal}
                toggle={() => setViewModal(!viewModal)}
            >
                <ModalHeader toggle={() => setViewModal(!viewModal)}>
                    Change Password
                </ModalHeader>
                <ModalBody>
                        <Form onSubmit={reset_password_handler}>
                            <Row>
                                <Col md={6} className="mb-2">
                                    <Label for="password"> Password </Label>
                                    <Input id="password" type='password' bsSize="sm" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Password" required />
                                </Col>
                                <Col md={6} className="mb-2">
                                    <Label for="password"> Confirm Password </Label>
                                    <Input id="cpassword" type='password' bsSize="sm" onChange={(e) => check_valid(e.target.value)} valid={passwordValid} invalid={!passwordValid} placeholder="Confirm Password" required />
                                </Col>
                                <Col md={12} className="mb-2">
                                    <Button className='text-white' color='primary' size="sm" block> Submit </Button> 
                                </Col>
                            </Row>
                        </Form>
                </ModalBody>
            </Modal>
        </>
    )
}

export default AssignUserView;