import React, { useState } from 'react';

import { Row, Col, Card, CardBody, CardTitle, Button, ButtonGroup, Modal, ModalHeader, ModalBody } from 'reactstrap';

import { BsCartPlusFill } from 'react-icons/bs';
import { FaPlus, FaMinus } from 'react-icons/fa';
import { MdOutlineInfo } from 'react-icons/md';

const ItemBox = ({ data, ...props }) => {
    const [count, setCount] = useState(1);
    const [viewModal, setViewModal] = useState(false);
    const [modalData, setModalData] = useState('');

    const quanyity_handler = (el) => {
        let temp = count;

        if (el === 1) {
            temp = temp + 1;
        } else if (el === -1 && temp !== 1) {
            temp = temp - 1;
        }

        setCount(temp);
    }

    const product_details = () => {
        let temp = <Row>
            <Col xs={4}>
                <img className='img-fluid' alt="load..." src={require("../../../assets/img/cropImg/" + data.img + ".png")} />
            </Col>
            <Col xs={8}>
                <h6 className='text-primary'>{data.name}</h6>
                <div className='mb-1'><b>variety:</b> {data.varity}</div>
                <div className='mb-1'><b>Unit:</b> {data.unit}</div>
                <div className='mb-1'><b>Price:</b>&#8377; {data.price}</div>
                <div className='mb-1'><b>Description:</b> </div>
                <div className='mb-1'>crop, in agriculture, a plant or plant product that can be grown and harvested extensively for profit or subsistence. By use, crops fall into six categories: food crops, for human consumption (e.g., wheat, potatoes); feed crops, for livestock consumption (e.g., oats, alfalfa); fibre crops, for cordage and textiles (e.g., cotton, hemp); oil crops, for consumption or industrial uses (e.g., cottonseed, corn); ornamental crops, for landscape gardening (e.g., dogwood, azalea); and industrial and secondary crops, for various personal and industrial uses (e.g., rubber, tobacco). </div>
            </Col>
        </Row>
        setModalData(temp);
        setViewModal(true);
    }

    return (
        <Col sm={3} className="mb-3">
            <Card>
                <MdOutlineInfo color="#3366FF" className='cursor' size={20} onClick={product_details} style={{ position: "absolute", top: "5px", right: '5px' }} />
                {data.img &&
                    <img height="150px" alt="load..." src={require("../../../assets/img/cropImg/" + data.img + ".png")} />
                }

                <CardBody>
                    <CardTitle tag="h6"> {data.name} </CardTitle>
                    <div className='d-flex justify-content-between'>
                        <div><b>Variety</b></div>
                        <div>{data.varity}</div>
                    </div>
                    <div className='d-flex justify-content-between'>
                        <div><b>Unit</b></div>
                        <div>{data.unit}</div>
                    </div>
                    <div className='d-flex justify-content-between'>
                        <div><b>Price</b></div>
                        <div>&#8377; {data.price}</div>
                    </div>
                    <div className='d-flex justify-content-between align-items-center'>
                        <div><b>Quantity</b></div>
                        <ButtonGroup
                            className="my-2"
                            size="sm"
                        >
                            <Button color="light" outline onClick={() => quanyity_handler(-1)}> <FaMinus color="#ff4136" /> </Button>
                            <Button color="light" disabled>{count}</Button>
                            <Button color="light" outline onClick={() => quanyity_handler(1)}> <FaPlus color="#00a500" /> </Button>
                        </ButtonGroup>
                    </div>
                    <Button className='text-white mt-2' color='primary' size="sm" block> <BsCartPlusFill className='me-1' size={20} /> Add to Cart</Button>
                </CardBody>
            </Card>
            <Modal
                centered
                scrollable
                size="lg"
                isOpen={viewModal}
                toggle={() => setViewModal(!viewModal)}
            >
                <ModalHeader toggle={() => setViewModal(!viewModal)}>
                    Product Detail
                </ModalHeader>
                <ModalBody>
                    {modalData}
                </ModalBody>
            </Modal>
        </Col>
    )

}

export default ItemBox;